Breadcrumb.Views.AdaptsToWindowHeight = Ember.Mixin.create({
  resizeSelectors: [],
  resizeSelf: true,
  padding: 0,
  ignoreAtWidthsLessThan: 767,
  heightDefaults: {},
  footerSelectors: {},
  minHeight: 100,

  didInsertElement: function() {
    this._super();
    this.resize();
    this.resizeHandler = B.bind(this.resize, this);
    $(window).on('resize', this.resizeHandler);
  },
  willDestroyElement: function() {
    $(window).off('resize', this.resizeHandler);
    this._super();
  },
  selToEl: function(sel) {
    if(!sel) { return null; }
    if(sel === 'view') { return this.$(); }
    return this.$(sel);
  },
  getFooterHeight: function(sel) {
    var footerSelectors = this.get('footerSelectors'), footersHeight = 0;
    if(footerSelectors[sel]) {
      var $footerEl = this.$(footerSelectors[sel]);
      var footerHeight = $footerEl.outerHeight();
      footersHeight += footerHeight;
    }
    return footersHeight;
  },
  elForSel: function(sel) {
    return sel ? this.$(sel) : this.$();
  },
  getWindowHeight: function() {
    return $(window).height();
  },
  getTop: function(sel) {
    var $el = this.elForSel(sel);
    var offset = $el.offset() || {};
    return offset.top || 0;
  },
  getPadding: function(sel) {
    var padding = this.get('padding');
    if(Ember.typeOf(padding) === 'object') { padding = padding[sel]; }
    return padding || 0;
  },
  getAvailHeight: function(sel) {
    var top = this.getTop(sel);
    var padding = this.getPadding(sel);
    var footers =  this.getFooterHeight(sel);
    return this.getWindowHeight() - top - padding - footers;
  },
  shouldResize: function() {
    return $(window).width() >= this.get('ignoreAtWidthsLessThan');
  },
  calculateElHeight: function(sel) {
    var elHeight = this.getAvailHeight(sel);
    if(elHeight < this.minHeight) { elHeight = this.minHeight; }
    return elHeight;
  },
  getHeightDefault: function(sel) {
    return this.heightDefaults[sel || 'this'] || 'inherit';
  },
  resizeSel: function(sel) {
    var $el = this.elForSel(sel);
    if(!$el) { return; }
    if(this.shouldResize()) {
      $el.css('height', this.calculateElHeight(sel));
    } else {
      $el.css('height', this.getHeightDefault(sel));
    }
  },
  resize: function() {
    // Don't adjust window height while testing.
    if(BREADCRUMB_ENV.TESTING) { return; }
    if(this.get('resizeSelf')) { this.resizeSel(null); }
    this.get('resizeSelectors').forEach(function(sel) {
      this.resizeSel(sel);
    }, this);
  }
});
