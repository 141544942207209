Breadcrumb.Utils.addCommas = function(nStr) {
  nStr += '';
  var x = nStr.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? '.' + x[1] : '';
  var rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2');
  }
  return x1 + x2;
};

Breadcrumb.Utils.humanizeDistance = function(value) {
  if(!value) { return ''; }
  var feet = Math.floor(parseFloat(value) * 3.28084);
  if(feet > 5280) {
    var miles = Math.floor(feet / 5280);
    return miles + 'mi';
  } else if(feet > 500) {
    var blocks = Math.floor(feet / 250);
    return blocks + ' blocks';
  } else {
    return Breadcrumb.Utils.addCommas(feet) + 'ft';
  }
};

Breadcrumb.Utils.isNumber = function(potentialNumber){
  return potentialNumber === parseInt(potentialNumber, 10);
};

/**
 * Check if URL is available
 * @param url
 * @returns {boolean}
 */
function urlIsAvailable(url){
  try {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    return http.status != 404;
  } catch (e) {
    console.info('An error was catched: ',e);
    return false;
  }

}
/**
 *  Create QR using Google Chart service
 * @param data
 * @returns {string}
 */
function createGoogleChart(data) {
  var apiUrl = "https://chart.googleapis.com/chart";
  var params = "cht=qr&chs=300x300&chl=%@".fmt(encodeURIComponent(data));
  return "%@?%@".fmt(apiUrl, params);
}

/**
 * Create QR using QR Server
 * @param data
 * @returns {string}
 */
function createQRServer(data) {
  var apiUrl = "https://api.qrserver.com/v1/create-qr-code/";
  var params = "size=300x300&data=%@".fmt(encodeURIComponent(data));
  return "%@?%@".fmt(apiUrl, params);
}

/**
 *  Create QR Code
 *  - api.qrserver.com is main service
 *  - chart.googleapis.com is reserve service
 * @param data
 * @returns {string}
 */
Breadcrumb.Utils.createQRCode = function(data) {
  var qrURL = createQRServer(data);
  if (urlIsAvailable(qrURL)) {
    return qrURL;
  }
  return createGoogleChart(data);
}

Ember.Handlebars.registerBoundHelper('dateformat',
  function dateformatHelper(value, options) {
    if(!value || value === '') { return ''; }
    var fmt = options.hash['format'] || 'MMM D h:mma z';
    if(typeof value === 'number' || typeof value === 'string') {
      value = new Date(value); }
    if(options.hash['utc']) {
        return moment(value).utc().format(fmt);
    }
    return moment(value).format(fmt);
  });

Ember.Handlebars.registerBoundHelper('intervalformat',
  function intervalFormatHelper(value1, value2) {
    if(!value1) { return ''; }
    if(!value2) { value2 = new Date(); }
    return Breadcrumb.Utils.timeFormatFns.interval(value1, value2);
  });

Ember.Handlebars.registerBoundHelper('truncate',
  function truncateHelper(value, options) {
    var length = options.hash && options.hash['length'] || 20;
    if(value && value.length > length) {
      return value.substr(0, length) + '..';
    } else {
      return value;
    }
  });

Ember.Handlebars.registerBoundHelper('distance',
  function distanceHelper(value, options) {
    return Breadcrumb.Utils.humanizeDistance(value);
  });

Ember.Handlebars.registerBoundHelper('pluralize',
  function pluralizeHelper(value, options) {
    Ember.assert("Must have the option 'word' or 'singular'.",
        !!options.hash['word'] || !!options.hash['singular']);
    var singular = options.hash['singular'] || options.hash['word'];
    var plural = options.hash['plural'] || (options.hash['word'] + 's');
    return value === 1 ? singular : plural;
  });

Breadcrumb.Utils.markdownToHtml = function(markdown) {
  var converter = new showdown.Converter({openLinksInNewWindow: true});
  if(!markdown) { return ''; }
  var regex = /_{4,}/g; // Note the {4,} which matches 4 or more underscores.
  var matches = markdown.match(regex);
  if (Array.isArray(matches)) {
    var replaces = matches
      .sort(function(a, b) { return b.length - a.length; })
      .map(function(from) { return [from, from.split('').join('\\')]; });
    for (var i = 0; i < replaces.length; i++) {
      var item = replaces[i];
      markdown = markdown.replace(item[0], item[1])
    }
  }
  var escaped = (markdown
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;"));

  return converter.makeHtml(escaped);
};

Ember.Handlebars.registerBoundHelper('markdown',
  function markdownHelper(value, options) {
    var html = Breadcrumb.Utils.markdownToHtml(value);
    return new Ember.Handlebars.SafeString(html);
  });

Ember.Handlebars.registerBoundHelper('thumbnail',
  function thumbnailHelper(mediaItem, size) {
    // size = tiny|small|medium|large
    return new Ember.Handlebars.SafeString('<img src="%@">'.fmt(
      Breadcrumb.Media.getThumbnailUrl(mediaItem, size)));
  });

Ember.Handlebars.registerBoundHelper('video',
  function videoHelper(mediaItem) {
    return new Ember.Handlebars.SafeString('<video controls>' +
      '<source type="video/mp4" src="%@"></video>'.fmt(
      Breadcrumb.Media.getVideoUrl(mediaItem)));
  });

Ember.computed.localtime = function computedLocaltime(timeKey, timezoneKey) {
  return Ember.computed(timeKey, timezoneKey, function() {
    var utcTime = this.get(timeKey), timezone = this.get(timezoneKey);
    if(!timezone){ return 'no timezone'; }
    if(!utcTime) { return null; }
    return moment(utcTime.getTime()).tz(timezone);
  });
};

Ember.computed.aliasWithDefault = function(aliasKey, defaultValue) {
  return Ember.computed(aliasKey, function(key, value) {
    if(value === undefined) {
      value = Ember.get(this, aliasKey);
      return value !== undefined ? value : defaultValue;
    } else {
      Ember.set(this, aliasKey, value);
      return value;
    }
  });
};

function computedOp(op) {
  return function(dependentKey, compareValue) {
    return Ember.computed(dependentKey, function(key) {
      var val = Ember.get(this, dependentKey);
      return op(val, compareValue);
    });
  };
}
Ember.computed.notEmpty = computedOp(function(val) {
  return !Ember.isEmpty(val); });
Ember.computed.equals = computedOp(function(val, compareValue) {
  return val === compareValue; });
Ember.computed.notEquals = computedOp(function(val, compareValue) {
  return val !== compareValue;});
Ember.computed.greaterThan = computedOp(function(val, compareValue) {
  return val > compareValue;});
Ember.computed.lessThan = computedOp(function(val, compareValue) {
  return val < compareValue;});
Ember.computed.greaterThanOrEqualTo = computedOp(
  function(val, compareValue) { return val >= compareValue; });
Ember.computed.lessThanOrEqualTo = computedOp(
  function(val, compareValue) { return val <= compareValue; });
