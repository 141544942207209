Breadcrumb.Blueprint = Ember.Object.extend({

  name: Ember.computed.readOnly('data.name'),
  title: Ember.computed.readOnly('data.title'),
  variables: Ember.computed.readOnly('data.variables'),
  routing: Ember.computed.readOnly('data.routing'),

  modes: function() {
    var modes = this.get('data.routing.modes');
    modes.forEach(function(mode, i) { mode.index = i; });
    return modes;
  }.property('data.routing.modes'),

  categories: function() {
    var categories = this.get('data.categories');
    categories.forEach(function(cat, i) {
      cat.index = i;
      cat.directIndex = (cat.direct_priority !== undefined ?
        cat.direct_priority : i);
    });
    return categories;
  }.property('data.categories'),

  getMode: function(modeName) {
    return this.get('modes').findBy('name', modeName);
  },

  getCategory: function(categoryName) {
    return this.get('categories').findBy('name', categoryName);
  },

  modeNameForCategory: function(categoryName) {
    var modes = this.data.routing.modes, modeName = null;
    modes.forEach(function(mode) {
      if(mode.category === categoryName) {
        modeName = mode.name;
      } else if(mode.categories && mode.categories.contains(categoryName)) {
        modeName = mode.name;
      }
    });
    return modeName;
  },

  roles: function() {
    var roles = this.get('data.roles');
    roles.forEach(function(role, i) { role.index = i; });
    return roles;
  }.property('data.roles')
});

Breadcrumb.Blueprint.reopenClass({
  loaded: {},
  load: function(name) {
    if(this.loaded[name]) { return Ember.RSVP.resolve(this.loaded[name]); }
    var self = this;
    return Breadcrumb.Assets
      .loadAsset(Ember.String.fmt('blueprints/%@.json', [name]))
      .then(function(data) {
        var blueprint = Breadcrumb.Blueprint.create({data: data});
        self.loaded[name] = blueprint;
        return blueprint;
      });
  },
  has: function(name) { return !!this.loaded[name]; },
  get: function(name) {
    Ember.assert("Blueprint " + name + " not loaded.", !!this.loaded[name]);
    return this.loaded[name];
  }
});
