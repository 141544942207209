Breadcrumb.geo = {
  distance: function(loc1, loc2) {
    var R = 6371, degToRad = 180.0 / Math.PI;
    var dLat = (loc2[1] - loc1[1]) * degToRad;
    var dLon = (loc2[0] - loc1[0]) * degToRad;
    var lat1 = loc1[1] * degToRad;
    var lat2 = loc2[1] * degToRad;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) *
            Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
    var d = R * c;
    return d;
  }
};
