Breadcrumb.IdentityTransform = DS.Transform.extend({
  deserialize: Breadcrumb.Utils.identity,
  serialize: Breadcrumb.Utils.identity
});

Breadcrumb.ListTransform = DS.Transform.extend({
  type: null,
  deserialize: function(l) {
    // set properties by camelcase.
    if(!l) { return []; }
    var type = this.get('type');
    if(typeof type === 'string') { type = Breadcrumb[type.classify()]; }
    return Ember.A(l.map(function(obj) {
      var props = {};
      for (var key in obj) {
        if(obj.hasOwnProperty(key)) {
          props[key.camelize()] = obj[key];
        }
      }
      return type.create(props);
    }));
  },
  serialize: function(l) {
    return l.map(function(obj) { return obj.toJSON(); });
  }
});


Ember.Application.initializer({
  name: "breadcrumb-transforms",
  after: "ember-data",

  initialize: function(container, application) {
    application.register('transform:list', Breadcrumb.IdentityTransform);
    application.register('transform:latlng', Breadcrumb.IdentityTransform);
    application.register('transform:obj', Breadcrumb.IdentityTransform);
    application.register('transform:steps', Breadcrumb.ListTransform.extend({
      type: 'step'
    }));
    application.register('transform:messages',
      Breadcrumb.ListTransform.extend({type: 'message'}));
  }
});
