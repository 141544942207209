define('breadcrumb/mixins/realtime', ['exports', 'ember'], function (exports, _ember) {
  /* globals PUBNUB */
  'use strict';

  exports['default'] = _ember['default'].Mixin.create(_ember['default'].Evented, {
    subscribedChannelName: null,
    channelFormat: '@env-model-@id',
    realtimeModel: _ember['default'].computed.alias('content'),
    mergedProperties: ['realtimeEvents'],

    unsubscribe: function unsubscribe() {
      _ember['default'].assert("Must be subscribed.", !!this.get('subscribedChannelName'));
      Breadcrumb.Realtime.unsubscribe(this.get('subscribedChannelName'));
      this.set('subscribedChannelName', null);
    },

    subscribe: function subscribe(channelName) {
      _ember['default'].assert("Must be unsubscribed.", !this.get('subscribedChannelName'));
      Breadcrumb.Logging.debug("subscribing to: " + channelName);
      Breadcrumb.Realtime.subscribe(channelName, Breadcrumb.bind(this.onMessage, this));
      this.set('subscribedChannelName', channelName);
    },

    publishEvent: function publishEvent(eventName, eventParams) {
      if (!this.get('subscribedChannelName')) {
        return;
      }
      var pubnub = Breadcrumb.Realtime.connect();
      pubnub.publish({
        channel: this.get('subscribedChannelName'),
        message: { events: [{ event: eventName, params: eventParams || {} }] }
      });
    },

    onMessage: function onMessage(message) {
      // ignore realtime messages from same client
      if (message.source_id === Breadcrumb.api._clientId) {
        return;
      }
      // ignore realtime messages with no events
      if (!message.events) {
        return;
      }
      var eventNames = (message.events || []).mapBy('event').join(', '),
          hasWildcardHandler = !!_ember['default'].get(this, 'realtimeEvents.wildcard');
      _ember['default'].Logger.info('%@: %@'.fmt(this.get('subscribedChannelName'), eventNames));
      message.events.forEach(function (evt) {
        var eventName = _ember['default'].String.camelize(evt.event),
            handler = _ember['default'].get(this, 'realtimeEvents.' + eventName);
        if (handler) {
          handler.call(this, evt.params);
        } else if (!hasWildcardHandler) {
          _ember['default'].Logger.debug('no handler on ' + this.constructor + ' for realtime event ' + eventName + '.');
        }
        this.trigger('realtimeEvent', evt);
      }, this);
      if (hasWildcardHandler) {
        this.realtimeEvents.wildcard.call(this, message.events);
      }
      this.trigger('realtimeMessage', message);
    },

    contentDidChange: (function () {
      this.updateSubscription();
    }).observes('content').on('init'),

    updateSubscription: function updateSubscription() {
      var objId = this.get('realtimeModel.id');
      var channelFormat = this.get('channelFormat');
      var oldChannelName = this.get('subscribedChannelName');
      var newChannelName = null;
      if (objId) {
        newChannelName = channelFormat.replace('@id', objId).replace('@env', BREADCRUMB_ENV.ENVIRONMENT_NAME);
      }
      if (newChannelName === oldChannelName) {
        return;
      }
      _ember['default'].Logger.debug('channel switch', oldChannelName, '->', newChannelName);
      if (oldChannelName) {
        this.unsubscribe();
      }
      if (newChannelName) {
        this.subscribe(newChannelName);
      }
    }
  });
});