Breadcrumb.Utils = {};

// Call standard confirm if not testing
Breadcrumb.Utils.confirm = function(msg) {
  if(BREADCRUMB_ENV.TESTING) { return true; } else { return confirm(msg); }
};

Breadcrumb.Utils.alert = function(msg) {
  if(BREADCRUMB_ENV.TESTING) { return; } else { alert(msg); }
};

Breadcrumb.Utils.prompt = function(msg, def) {
  if(BREADCRUMB_ENV.TESTING) { return null; } else { return prompt(msg, def); }
};

Breadcrumb.Utils.deparam = function deparam(query) {
  var pairs, i, keyValuePair, key, value, map = {};
  if (query.slice(0, 1) === '?') { query = query.slice(1); }
  pairs = query.split('&');
  for (i = 0; i < pairs.length; i += 1) {
    keyValuePair = pairs[i].split('=');
    key = decodeURIComponent(keyValuePair[0]);
    value = (keyValuePair.length > 1) ? decodeURIComponent(keyValuePair[1]) : undefined;
    value = value.replace(/\+/g, ' ');
    map[key] = value;
  }
  return map;
};

// Copy of Underscore's _.range
Breadcrumb.Utils.range = function rangeUtil(start, stop, step) {
  if (arguments.length <= 1) {
    stop = start || 0;
    start = 0;
  }
  step = arguments[2] || 1;

  var len = Math.max(Math.ceil((stop - start) / step), 0);
  var idx = 0;
  var range = new Array(len);

  while(idx < len) {
    range[idx++] = start;
    start += step;
  }

  return range;
};


// Copy of Underscore's _.debounce
Breadcrumb.Utils.debounce = function debounceUtil(func, wait, immediate) {
  var timeout, result;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) result = func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) result = func.apply(context, args);
    return result;
  };
};

Breadcrumb.Utils.identity = function identityUtil(value) { return value; };

Breadcrumb.Utils.guid = function guidUtil() {
  var d = Breadcrumb.now().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (d + Math.random()*16)%16 | 0;
      d = Math.floor(d/16);
      return (c==='x' ? r : (r&0x7|0x8)).toString(16);
  });
  return uuid;
};

Breadcrumb.Utils.slugify = function slugifyUtil(text) {
  if(!text) { return ""; }
  text = text.toLowerCase();
  text = text.replace(/[^a-zA-Z0-9\s\-]+/ig, '');
  text = text.replace(/\-/gi, " ");
  text = text.replace(/\s+/gi, "-");
  return text;
};

Breadcrumb.Utils.keyify = function slugifyUtil(text) {
  if(!text) { return ""; }
  text = text.toLowerCase();
  text = text.replace(/[^a-zA-Z0-9_\s\-\.]+/ig, '');
  text = text.replace(/\-/gi, " ");
  text = text.replace(/\s+/gi, "_");
  return text;
};

Breadcrumb.Utils.parseShortTime = function(value, tz) {
  var now = moment(Breadcrumb.now());
  var time = now.clone().local().tz(tz);
  value = value.toLowerCase().trim();
  var amOrPm = value.substr(-2);
  if(amOrPm !== 'am' && amOrPm !== 'pm') { return; }
  var isPm = amOrPm === 'pm';
  var h = value.split(":")[0];
  var m = value.split(":")[1];
  if(!h || !m) { return null; }
  h = parseInt(h, 10);
  m = parseInt(m, 10);
  if(isNaN(h) || isNaN(m) || h === undefined || m === undefined) {
    return null; }
  if(h < 0 || h > 12) { return null; }
  if(!isPm && h === 12) { h -= 12; }
  if(isPm && h < 12) { h += 12; }
  if(m < 0 || m > 59) { return null; }
  time.hour(h).minute(m).second(0).millisecond(0);
  // don't allow times more than a day in the future.
  while(time.diff(now) < 0) { time.add('days', 1); }
  while(time.diff(now, 'days', true) > 1) { time.subtract('days', 1); }
  return new Date(time.unix() * 1000);
};

Breadcrumb.Utils.isMobile = function isMobileUtil() {
  return !!navigator.userAgent.match(/iPhone|iPad|iPod|Android|Opera|IEMobile|BlackBerry|BB10|Mobile/i); };

Breadcrumb.Utils.isAndroid = function isAndroidUtil() {
  return !!navigator.userAgent.match(/Android/i); };

Breadcrumb.Utils.isIOS = function isIOSUtil() {
  return !!navigator.userAgent.match(/iPhone|iPad|iPod/i); };

Breadcrumb.Utils.isSafari = function isSafariUtil() {
  return Breadcrumb.Utils.isIOS() && BREADCRUMB_ENV.PLATFORM !== 'native'; };

Breadcrumb.Utils.isBlackberry = function isBlackberryUtil() {
  return !!navigator.userAgent.match(/BlackBerry|BB10/i); };

Breadcrumb.bind = Breadcrumb.Utils.bindFn = function bindFn(fn, context) {
  return function() { return fn.apply(context, arguments); }; };

Breadcrumb.Utils.sortBy = function sortByUtil(arr, fn) {
  if(Ember.typeOf(fn) === 'string') {
    var key = fn, isNegative = false;
    if(key.substr(0, 1) === '-') { isNegative = true; key = key.substr(1); }
    fn = function(obj) {
      if(isNegative) { return -Ember.get(obj, key); }
      return Ember.get(obj, key);
    };
  }
  arr.sort(function(a, b) {
    var aVal = fn(a), bVal = fn(b);
    return aVal < bVal ? -1 : aVal > bVal ? 1 : 0;
  });
};

Breadcrumb.Utils.jQueryPromise = function(promise) {
  Ember.assert("Must pass a promise to jQueryPromise()",
    promise && !!promise.then);
  var deferred = $.Deferred();
  promise.then(deferred.resolve, deferred.reject);
  return deferred.promise();
};

Breadcrumb.Utils.phoneFormat = function(v) {
  if(!v) { return ''; }
  if(typeof(v) === 'number') { v = v.toString(); }
  if(v[0] === '1') { v = v.slice(1); }
  v = v.replace(/\D/ig, '');
  return v.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

Breadcrumb.computed = {};

Breadcrumb.computed.defaultToConst = function(aliasKey, defaultValue) {
  return Ember.computed(aliasKey, {
    get: function() {
      return this.get(aliasKey) || defaultValue;
    },
    set: function(key, newValue) {
      this.set(aliasKey, newValue);
      return newValue || defaultValue;
    }
  });
};

Breadcrumb.computed.defaultTo = function(aliasKey, defaultKey) {
  return Ember.computed(aliasKey, defaultKey, {
    get: function() {
      return this.get(aliasKey) || this.get(defaultKey);
    },
    set: function(key, newValue) {
      this.set(aliasKey, newValue);
      return newValue || this.get(defaultKey);
    }
  });
};
