var forEach = Ember.EnumerableUtils.forEach, map = Ember.EnumerableUtils.map;

Breadcrumb.Behavior = Ember.Object.extend({
  label: function() {
    var label = this.get('title');
    if(this.get('isLinked')) { label += ' (Linked)'; }
    return label;
  }.property('title')
});

Breadcrumb.Behavior.reopenClass({
  loaded: {},
  fieldsets: {},
  instantiate: function(data, behaviorName) {
    var behaviorData = data.behaviors[behaviorName];
    var fieldNames = [], fieldsets = data.fieldsets || {};
    if(fieldsets.common_before) {
      fieldNames = fieldNames.concat(fieldsets.common_before); }
    if(behaviorData.fields) {
      forEach(behaviorData.fields, function(fieldName) {
        if(fieldsets[fieldName]) {
          fieldNames = fieldNames.concat(fieldsets[fieldName]);
        } else {
          fieldNames.push(fieldName);
        }
      });
    }
    if(fieldsets.common_after) {
      fieldNames = fieldNames.concat(fieldsets.common_after); }
    var fields = map(fieldNames, function(fieldName) {
      return data.fields[fieldName];
    });
    return this.create({
      // normal options
      name: behaviorName,
      title: behaviorData.title,
      description: behaviorData.description,
      fields: fields,
      submissionType: behaviorData.submission_type,
      submissionOptions: behaviorData.submission_options,
      // linked behavior options
      isLinked: !!behaviorData.is_linked,
      linkedTeamsMin: behaviorData.linked_teams_min,
      linkedTeamsMax: behaviorData.linked_teams_max
    });
  },
  load: function() {
    return Breadcrumb.Assets
      .loadAsset(Ember.String.fmt('behaviors.json'))
      .then(B.bind(function(data) {
        for(var behaviorName in data.behaviors) {
          this.loaded[behaviorName] = this.instantiate(data, behaviorName);
        }
        this.fieldsets = data.fieldsets;
      }, this));
  },
  get: function(name) {
    Ember.assert("Behavior " + name + " not loaded.", !!this.loaded[name]);
    return this.loaded[name];
  },
  all: function() {
    var all = [];
    for(var behaviorName in this.loaded) {
      all.push(this.loaded[behaviorName]); }
    return all;
  }
});
