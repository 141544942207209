define('breadcrumb/components/time-until', ['exports', 'ember', 'breadcrumb/templates/components/time-until'], function (exports, _ember, _breadcrumbTemplatesComponentsTimeUntil) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _breadcrumbTemplatesComponentsTimeUntil['default'],
    tagName: '',
    format: 'human',

    time: _ember['default'].computed('Breadcrumb.ticker', 'input', function () {
      var format = this.get('format');
      var fn = Breadcrumb.Utils.timeFormatFns[format];
      return fn(this.get('input'), { relativeTimeType: 'until' });
    })
  });
});