define('breadcrumb/mixins/realtime-reloader', ['exports', 'ember', 'breadcrumb/mixins/realtime-reloader'], function (exports, _ember, _breadcrumbMixinsRealtimeReloader) {
  'use strict';

  exports['default'] = _ember['default'].Mixin.create(_breadcrumbMixinsRealtimeReloader['default'], {
    realtimeEvents: {
      wildcard: function wildcard() {
        this.get('content').safeReload();
      }
    }
  });
});