Breadcrumb.EditableTextComponent = Ember.Component.extend({
  /* Basically an ember text input view with some custom behavior */
  classNames: ['editable-text'], // applied to view div
  value: '', // bound to controller
  _buffer: '', // bound to input element's value
  action: '',  // sent to context controller
  placeholder: '',
  clearIcon: false,
  layoutName: "components/editable-text",

  didInsertElement: function() {
    this.set('_buffer', this.get('value'));
  },

  keyDown: function(evt) {
    // enter or tab saves
    if(evt.keyCode === 13 || evt.keyCode === 9){
      if(this.get('_buffer') !== this.get('value')) {
        this.commit();
      }
      $(evt.target).blur();
    }
    // escape cancels
    if(evt.keyCode === 27){
      this.set('_buffer', this.get('value'));
      $(evt.target).blur();
    }
  },

  focusOut: function() {
    if(this.get('_buffer') !== this.get('value')) {
      this.commit();
    }
  },

  valueDidChange: function() {
    this.set('_buffer', this.get('value'));
  }.observes('value'),

  commit: function() {
    // set value bound to controller with _buffer contents
    this.set('value', this.get('_buffer'));
    this.sendAction();
  },

  // prevent drag n drop
  dragOver: function(event) {
    event.preventDefault();
  },

  showClear: function() {
    if(!this.get('clearIcon')) { return false; }
    if(!this.get('_buffer')) { return false; }
    return true;
  }.property('clearIcon', '_buffer'),

  actions: {
    clear: function() {
      if(this.get('value') !== '') { this.set('value', ''); }
      if(this.get('_buffer') !== '') { this.set('_buffer', ''); }
    }
  }
});


Breadcrumb.EditableSlugComponent = Breadcrumb.EditableTextComponent.extend({
  _bufferDidChange: function() {
    if(this.get('_buffer') === this.get('value')) { return; }
    var slugified = Breadcrumb.Utils.slugify(this.get('_buffer'));
    if (slugified !== this.get('_buffer')) {
      this.set('_buffer', slugified);
    }
  }.observes('_buffer')
});

Breadcrumb.EditableKeyComponent = Breadcrumb.EditableTextComponent.extend({
  _bufferDidChange: function() {
    if(this.get('_buffer') === this.get('value')) { return; }
    var keyified = Breadcrumb.Utils.keyify(this.get('_buffer'));
    if(keyified !== this.get('_buffer')) {
      this.set('_buffer', keyified);
    }
  }.observes('_buffer')
});



Breadcrumb.EditableIntegerComponent = Breadcrumb.EditableTextComponent.extend({
  classNames: ['editable-integer'],
  placeholder: 0,
  min: null,
  max: null,

  commit: function() {
    this.validate();
    this.set('value', this.get('_buffer'));
    this.sendAction();
  },

  validate: function() {
    // minimal min max validation
    var buf = parseInt(this.get('_buffer'),10);
    var min = this.get('min');
    var max = this.get('max');
    if(typeof min !== 'undefined' && buf < min){ buf = min; }
    if(max && buf > max){ buf = max; }
    this.set('_buffer', buf);
  }
});
