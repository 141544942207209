(function() {

  Breadcrumb.Utils.timeFormatFns = {
    human: function(time) {
      if(!time) { return ''; }
      var timeObj = moment(time);
      return timeObj.fromNow();
    },
    humanTimeOnly: function(time) {
      if(!time) { return ''; }
      var timeObj = moment(time);
      var text = timeObj.fromNow();
      if(text.substr(0, 3) === 'in ') { text = text.substr(3); }
      if(text.substr(-4) === ' ago') {
        text = text.substr(0, text.length - 4); }
      return text;
    },
    timer: function(time, options) {
      if(!time) { return ''; }
      if(options && options.relativeTimeType === 'until') {
        return Breadcrumb.Utils.timeFormatFns.interval(Breadcrumb.now(), time);
      } else {
        return Breadcrumb.Utils.timeFormatFns.interval(time, Breadcrumb.now());
      }
    },
    interval: function(d1, d2) {
      var delta = (d2.getTime() - d1.getTime()) / 1000, prefix = '';
      if(delta < 0) { delta = Math.abs(delta); prefix = '-'; }
      var secs = Math.floor(delta) % 60;
      var mins = Math.floor((delta / 60) % 60);
      var hrs = Math.floor(delta / 3600);
      return '%@%@%@:%@'.fmt(
        prefix,
        hrs > 0 ? (hrs + ':') : '',
        mins > 9 ? mins : ('0' + mins),
        secs > 9 ? secs : ('0' + secs));
    }
  };

  var ticker = Ember.Object.create({ticker: null});
  Breadcrumb.ticker = ticker;
  function updateTicker() {
    if (Ember.testing) { return; } // FIXME
    Breadcrumb.propertyWillChange('ticker');
    ticker.set('ticker', Breadcrumb.now());
    Breadcrumb.propertyDidChange('ticker');
  }
  setInterval(updateTicker, 5000);

  var formatFns = Breadcrumb.Utils.timeFormatFns;
  var normalizePath = Ember.Handlebars.normalizePath;
  function rerenderBoundHelperView() {
    Ember.run.scheduleOnce('render', this, 'rerender');
  }
  function relativeTimeHelper(timeProperty, options, formatOpts) {
    var data = options.data,
      format = options.hash['format'] || 'human',
      currentContext = (options.contexts && options.contexts[0]) || this,
      normalized, formatFn;
    data.properties = [timeProperty];
    normalized = normalizePath(currentContext, timeProperty, data);
    var bindView = new Ember._SimpleHandlebarsView(
      normalized.path, normalized.root, !options.hash.unescaped, options.data
    );
    formatFn = formatFns[format] || formatFns.human;
    bindView.normalizedValue = function() {
      var value = Ember._SimpleHandlebarsView.prototype.normalizedValue.call(bindView);
      return formatFn.call(data.view, value, options);
    };
    data.view.appendChild(bindView);
    data.view.registerObserver(normalized.root, normalized.path, bindView,
      rerenderBoundHelperView);
    data.view.registerObserver(ticker, 'ticker', bindView,
      rerenderBoundHelperView);
  }
  function timeSinceHelper(timeProperty, options) {
    return "FIXME";
    options.relativeTimeType = 'since';
    return relativeTimeHelper(timeProperty, options);
  }
  function timeUntilHelper(timeProperty, options) {
    return "FIXME";
    options.relativeTimeType = 'until';
    return relativeTimeHelper(timeProperty, options);
  }
  relativeTimeHelper._rawFunction = formatFns.human;
  // Ember.Handlebars.registerHelper('timeSince', timeSinceHelper);
  // Ember.Handlebars.registerHelper('timeUntil', timeUntilHelper);

})();
