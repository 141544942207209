var onReconnectFunc = undefined;

Breadcrumb.Realtime = {
  subscriptions: {},

  onReconnect: function(func) {
    onReconnectFunc = func;
  },

  connect: function(pub_key, sub_key) {
    return PUBNUB({
      ssl           : true,
      publish_key   : pub_key || "pub-c-1b7fc1ea-94d3-4b6c-a259-c4abf61d0fc2",
      subscribe_key : sub_key || "sub-c-18db8a1c-ac61-11e2-9c61-12313f022c90"
    });
  },

  subscribe: function(channel, callback) {
    this.subscriptions[channel] = callback;
    if(BREADCRUMB_ENV.TESTING) { return; }
    try {

      var pubnub = this.connect("pub-c-1b7fc1ea-94d3-4b6c-a259-c4abf61d0fc2",
        "sub-c-18db8a1c-ac61-11e2-9c61-12313f022c90");


      pubnub.subscribe({
        channel: channel,
        callback: callback,
        restore: true,
        connect: function() { console.log('connected')},
        disconnect: function() { },
        reconnect: function() {
          if (onReconnectFunc) {
            onReconnectFunc();
          }
        },
        error: function() {
          console.error(arguments);
        }
      });
    } catch(err) {
      console.error("Pubnub subscription error: " + channel + ", " + err);
    }
  },

  unsubscribe: function(channel) {
    if(this.subscriptions[channel]) { delete this.subscriptions[channel]; }
    if(BREADCRUMB_ENV.TESTING) { return; }
    try {
      var pubnub = this.connect("pub-c-1b7fc1ea-94d3-4b6c-a259-c4abf61d0fc2",
        "sub-c-18db8a1c-ac61-11e2-9c61-12313f022c90");
      pubnub.unsubscribe({channel: channel});
    } catch(err) {
      console.error("Pubnub unsubscription error: " + channel + ", " + err);
    }
  },

  reset: function() {
    var channel, callback;
    for(channel in this.subscriptions) {
      callback = this.subscriptions[channel];
      this.unsubscribe(channel);
      this.subscribe(channel, callback);
    }
  },

  publish: function(channel, message) {
    if(!BREADCRUMB_ENV.TESTING) { return; }
    // If testing, directly call callback.
    var callback = this.subscriptions[channel];
    if(callback) { Ember.run(function() { callback(message); }); }
  },

  status: function() {
    PUBNUB['each-channel'](function(channel) {
      console.log(channel.name + ': ' +
        (channel.connected ? 'connected' : 'not connected') + ', ' +
        (channel.subscribed ? 'subscribed' : 'not subscribed') +
        (channel.disconnected ? ', disconnected' : ''));
    });
  },

  channels: function() {
    var channels = [];
    PUBNUB['each-channel'](function(c) { channels.push(c); });
    return channels;
  }

};
