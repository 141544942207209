
Breadcrumb.City = Breadcrumb.Model.extend({
  name: DS.attr('string'),
  countryCode: DS.attr('string'),
  admin1Code: DS.attr('string'),
  location: DS.attr('latlng'),
  timezone: DS.attr('string'),

  games: DS.hasMany('city'),
  gamezones: DS.hasMany('gamezone'),
  missions: DS.hasMany('mission'),

  displayName: function() {
    var name = this.get('name') + ', ', countryCode = this.get('countryCode');
    name += (countryCode === 'US') ? this.get('admin1Code') : countryCode;
    return name;
  }.property('name', 'countryCode', 'admin1Code')
});

Breadcrumb.Gamezone = Breadcrumb.Model.extend({

  organizationId: DS.attr('string'),
  name: DS.attr('string'),
  city: DS.belongsTo('city'),

  notes: DS.attr('string'),
  isDangerzone: DS.attr('boolean'),
  isArchived: DS.attr('boolean'),
  updatedAt: DS.attr('date'),

  centerLocation: DS.attr('latlng'),
  hull: DS.attr('list')
});

Breadcrumb.Organization = Breadcrumb.Model.extend({
  title: DS.attr('string')
});

Breadcrumb.Creator = Breadcrumb.Model.extend({
  login: DS.attr('string'),
  firstName: DS.attr('string'),
  lastName: DS.attr('string'),
  email: DS.attr('string'),
  isAdmin: DS.attr('boolean'),
  isSuper: DS.attr('boolean'),

  displayName: function() {
    return this.get('firstName') || this.get('login');
  }.property('firstName', 'login')
});

Breadcrumb.Flavor = Breadcrumb.Model.extend({
  name: DS.attr('string'),
  blueprintName: DS.attr('string'),
  type: DS.attr('string'),
  organizationId: DS.attr('string'),
  gameId: DS.attr('string'),
  parentId: DS.attr('string'),
  isLocked: DS.attr('boolean'),
  entries: DS.attr('list'),
  isArchived: DS.attr('boolean'),
  createdAt: DS.attr('date'),
  createdBy: DS.belongsTo('creator'),
  updatedAt: DS.attr('date'),
  updatedBy: DS.belongsTo('creator'),

  copy: function(props) {
    props = $.extend({
      organizationId: this.get('organizationId'),
      blueprintName: this.get('blueprintName'),
      name: this.get('name'),
      entries: this.get('entries'),
      isLocked: this.get('isLocked'),
      isArchived: this.get('isArchived')
    }, props || {});
    return this.store.createRecord('flavor', props);
  },

  inGame: function (game) {
    if (game.get('flavors').contains(this)) {
      return true;
    }
    var flavor = game.get('flavors').find(function (item) {
      return item.get('id') === this.get('id') || item.get('parentId') === this.get('id');
    });
    return !!flavor;
  }
});

Breadcrumb.Game = Breadcrumb.Model.extend({

  organizationId: DS.attr('string'),
  city: DS.belongsTo('city'),
  gamezone: DS.belongsTo('gamezone'),

  name: DS.attr('string'),
  title: DS.attr('string'),

  blueprintName: DS.attr('string'),
  flavorName: DS.attr('string'),
  flavors: DS.hasMany('flavor', {async: true}),

  routing: DS.attr('obj'),

  isArchived: DS.attr('boolean'),
  template: DS.attr('boolean'),

  welcomeMessage: DS.attr('string'),
  endedEarlyMessage: DS.attr('string'),
  endedMessage: DS.attr('string'),
  teamGatedMessage: DS.attr('string'),
  infoMessage: DS.attr('string'),

  options: DS.attr('obj'),

  createdAt: DS.attr('date'),
  createdBy: DS.belongsTo('creator'),
  updatedAt: DS.attr('date'),
  updatedBy: DS.belongsTo('creator'),
  lastRanAt: DS.attr('date'),
  lastRanBy: DS.belongsTo('creator'),

  tags: DS.attr('list'),
  notes: DS.attr('string'),
  variables: DS.attr('obj'),

  legacyId: DS.attr('number'),

  centerLocation: DS.attr('latlng'),
  hull: DS.attr('list'),

  hubspotDealId: DS.attr('number'),
  dealType: DS.attr('string'),

  blueprint: function() {
    return Breadcrumb.Blueprint.get(this.get('blueprintName') || 'gogame');
  }.property('blueprintName')
});

Breadcrumb.GameSerializer = Breadcrumb.Serializer.extend({
  attrs: {flavors: {embedded: 'ids'}}
});

Breadcrumb.StepScoringMethods = {
  NORMAL: 0,
  COMPLETION_TIME: 1,
  VOTES: 2
};

Breadcrumb.Step = Ember.Object.extend(Ember.Validations, {

  stepId: null,
  behaviorName: null,
  title: null,
  instructions: null,
  content: null,
  scoring: 0,

  behavior: Ember.computed('behaviorName', {
    set: function(key, value) {
      this.set('behaviorName', value.name);
      return value;
    },
    get: function() {
      Ember.assert("Behavior name must be defined.",
        !!this.get('behaviorName'));
      return Breadcrumb.Behavior.get(this.get('behaviorName'));
    }
  }),

  toJSON: function() {
    return {
      step_id: this.get('stepId'),
      behavior_name: this.get('behaviorName'),
      title: this.get('title'),
      instructions: this.get('instructions'),
      content: this.get('content'),
      scoring: this.get('scoring')
    };
  },

  copy: function() {
    return Breadcrumb.Step.create({
      stepId: this.get('stepId'),
      behaviorName: this.get('behaviorName'),
      title: this.get('title'),
      instructions: this.get('instructions'),
      content: Ember.copy(this.get('content'), true),
      scoring: this.get('scoring')
    });
  },

  getContentValueOrDefault: function(key) {
    var fields = this.get('behavior.fields');
    if(!fields) { return null; }
    var field = fields.filterBy('name', key)[0];
    if(!field) { return null; }
    var value = this.get('content')[key];
    if(value !== undefined) { return value; }
    if(field['default']) { return field['default']; }
    return null;
  },

  contentWithDefaults: function() {
    var finalContent = {}, stepContent = this.get('content') || {};
    this.get('behavior.fields').forEach(function(field) {
      var value = stepContent[field.name];
      if(value === undefined && field['default']) {
        value = field['default']; }
      if(value !== undefined) {
        finalContent[field.name] = value; }
    }, this);
    return finalContent;
  }.property('content'),

  fieldsAndValues: function() {
    var content = this.get('content') || {};
    return this.get('behavior.fields')
      .filter(function(field) {
        if(!Ember.isEmpty(content[field.name])) { return true; }
        if(field['default']) { return true; }
        return false;
      }, this).map(function(field) {
        var value = content[field.name], isDefault = false;
        if(value === undefined) {
          value = field['default']; isDefault = true; }
        return {field: field, isDefault: isDefault, value: value};
      }, this).sort(function(a, b) {
        return (a.isDefault ? 0 : 1) < (b.isDefault ? 0 : 1) ? 1 : -1;
      });
  }.property('content', 'behavior'),

  validations: function() {
    var validations = {
      title: {presence: true},
      instructions: {presence: true}
    };
    this.get('behavior.fields').forEach(function(field) {
      // name, type, label, required
      var validation = {};
      if(field.type === 'int') {
        validation.numericality = {
          onlyInteger: true,
          allowBlank: !field.required
        };
      } else if(field.type === 'list:text') {
        validation.textList = {
          allowAnyBlank: false,
          allowBlank: !field.required
        };
      } else if(field.type === 'list:text+check') {
        validation.textAndCheckList = {
          allowAnyBlank: false,
          requireOneChecked: true,
          allowBlank: !field.required
        };
      } else if(field.type === 'list:text+number') {
        validation.textAndNumberList = {
          allowAnyBlank: false,
          requireAllNumbers: true,
          allowBlank: !field.required
        };
      }else if(field.type === 'list:text+value') {
        validation.textAndValueList = {
          allowAnyBlank: false,
          allowBlank: !field.required
        };
      } else {
        if(field.required) { validation.presence = true; }
      }
      validations['content.' + field.name] = validation;
    });
    return validations;
  }.property('behavior'),

  scoringTitle: function() {
    switch(this.get('scoring')) {
      case 0: return "normal";
      case 1: return "timed";
      case 2: return "votes";
    }
  }.property('scoring'),

  scoringIcon: function() {
    switch(this.get('scoring')) {
      case 0: return "";
      case 1: return "<i class='icon-time'></i>"; // timed
      case 2: return "<i class='icon-pencil'></i>"; // votes
    }
  }.property('scoring')
});


Breadcrumb.MissionTag = Breadcrumb.Model.extend({
  name: DS.attr('string')
});


Breadcrumb.Mission = Breadcrumb.Model.extend(Ember.Validations, {
  organizationId: DS.attr('string'),
  game: DS.belongsTo('game'),
  list: DS.belongsTo('mission_list', { inverse: null }),
  originalMission: DS.belongsTo('mission', { inverse: null }),

  type: DS.attr('string'),
  // relations between mission and mission list
  parent: DS.belongsTo('mission', { inverse: null }),
  parentList: DS.belongsTo('mission_list', { inverse: null }),
  changed: DS.attr('boolean'),

  createdAt: DS.attr('date'),
  createdBy: DS.belongsTo('creator'),
  updatedAt: DS.attr('date'),
  updatedBy: DS.belongsTo('creator'),

  city: DS.belongsTo('city'),
  gamezone: DS.belongsTo('gamezone'),

  name: DS.attr('string'),
  blueprintName: DS.attr('string'),
  modeName: DS.attr('string'),
  categoryName: DS.attr('string'),
  steps: DS.attr('steps'),
  location: DS.attr('latlng'),
  legacyId: DS.attr('number'),
  notes: DS.attr('string'),

  isEnabled: DS.attr('boolean'),
  maxTeams: DS.attr('number'),
  priority: DS.attr('number'),
  points: DS.attr('number'),
  isCompleted: DS.attr('boolean'),

  isArchived: DS.attr('boolean'),
  hasBeenPlayed: DS.attr('boolean'),
  // Default values for the Media
  inGallery: DS.attr('boolean', {defaultValue: true}),
  inSlideshow: DS.attr('boolean', {defaultValue: true}),

  //tags: DS.attr('list'),
  tags: DS.hasMany('mission_tag', {async: true}),

  assets: DS.attr('list'),

  blueprint: function() {
    var blueprintName = this.get('blueprintName') || 'gogame';
    return Breadcrumb.Blueprint.get(blueprintName);
  }.property('blueprintName'),

  category: Ember.computed('categoryName', {
    get: function() {
      return this.get('blueprint.categories')
        .findBy('name', this.get('categoryName'));
    },
    set: function(key, value) {
      this.set('categoryName', value.name);
      return value;
    }
  }),

  mode: function() {
    return this.get('blueprint').getMode(this.get('modeName'));
  }.property('modeName'),

  validations: {
    name: {presence: true},
    categoryName: {presence: true},
    notes: {
      presence: {
        validator: function(object, attribute, value) {
          var category = object.get('categoryName');
          var blueprintName = object.get('blueprint').get('name');
          if ((category === 'sneak' || category === 'question' && blueprintName !== 'gogameshow') && !value) {
            object.get('validationErrors').add(attribute, 'required');
          }
        }
      }
    },
    steps: {presence: true}
  },

  priorityOrder: function() {
    if(!this.get('isEnabled')) { return 3; }
    return 2 - this.get('priority');
  }.property('priority', 'isEnabled'),

  hasMultipleSteps: function() {
    return this.get('steps.length') > 1;
  }.property('steps.length'),

  copy: function(props) {
    props = $.extend({
      originalMission: this.get('originalMission') ? this.get('originalMission') : this,
      organizationId: this.get('organizationId'),
      createdBy: this.get('createdBy'),
      updatedBy: this.get('updatedBy'),
      updatedAt: this.get('updatedAt'),
      name: this.get('name'),
      categoryName: this.get('categoryName'),
      modeName: this.get('modeName'),
      city: this.get('city'),
      gamezone: this.get('gamezone'),
      steps: this.get('steps').invoke('copy'),
      location: this.get('location'),
      isEnabled: this.get('isEnabled'),
      maxTeams: this.get('maxTeams'),
      priority: this.get('priority'),
      points: this.get('points'),
      notes: this.get('notes'),
      // tags: this.get('tags')
    }, props || {});
    return this.store.createRecord('mission', props);
  }
});

Breadcrumb.MissionSerializer = Breadcrumb.Serializer.extend({
  attrs: {
    tags: {
      embedded: 'ids'
    }
  }
});

Breadcrumb.MissionGroupTypes = {
  START_TRACK: 'start_track',
  END_TRACK: 'end_track',
  PREREQUISITE: 'prerequisite',
  PROFILE_PREREQUISITE: 'profile_prerequisite',
  MAX_ONE: 'max_one',
  SEQUENCE: 'sequence',
  DELAYED_SEQUENCE: 'delayed_sequence',
  FOLLOW_AT_END: 'follow_at_end'
};

Breadcrumb.MissionGroup = Breadcrumb.Model.extend({
  createdAt: DS.attr('date'),
  updatedAt: DS.attr('date'),
  game: DS.belongsTo('game'),
  groupType: DS.attr('string'),
  groupInfo: DS.attr('obj'),
  missions: DS.hasMany('mission', {async: true}),
  portalTeamName: DS.attr('string')
});

Breadcrumb.MissionGroupSerializer = Breadcrumb.Serializer.extend({
  attrs: {missions: {embedded: 'ids'}}
});

Breadcrumb.MissionList = Breadcrumb.Model.extend({
  organizationId: DS.attr('string'),
  name: DS.attr('string'),
  isShared: DS.attr('boolean'),
  isArchived: DS.attr('boolean'),
  isPinned: DS.attr('boolean'),
  city: DS.belongsTo('city'),
  gamezone: DS.belongsTo('gamezone'),

  createdBy: DS.belongsTo('creator'),
  createdAt: DS.attr('date'),
  updatedAt: DS.attr('date'),
  updatedBy: DS.belongsTo('creator'),

  missions: DS.hasMany('mission')
});


Breadcrumb.PlaythroughStatuses = {
  CLOSED: 100,
  STARTED: 300,
  PAUSED: 400,
  ENDING: 500,
  ENDED: 600,
  VOTING: 700,
  ARCHIVED: 800
};

Breadcrumb.Playthrough = Breadcrumb.Model.extend({
  game: DS.belongsTo('game'),
  organization: DS.belongsTo('organization'),
  createdBy: DS.belongsTo('creator'),
  createdAt: DS.attr('date'),
  updatedBy: DS.belongsTo('creator'),
  updatedAt: DS.attr('date'),
  name: DS.attr('string'),
  slug: DS.attr('string'),
  pin: DS.attr('string'),
  task_id: DS.attr('string'),
  startedAt: DS.attr('date'),
  duration: DS.attr('number'),
  endAt: DS.attr('date'),
  endedAt: DS.attr('date'),

  status: DS.attr('number'),
  pauseMessage: DS.attr('string'),
  numTeams: DS.attr('number'),
  isActive: DS.attr('boolean'),
  isReal: DS.attr('boolean'),
  isArchived: DS.attr('boolean'),

  options: DS.attr('obj'),

  votingWaypoint: DS.belongsTo('waypoint'),

  galleryIsPublic: DS.attr('boolean'),
  galleryUrl: DS.attr('string'),
  galleryPassword: DS.attr('string'),
  galleryArchivePath: DS.attr('string'),

  judgingDisabled: DS.attr('boolean'),
  allPointsDisabled: DS.attr('boolean'),
  noWatermarks: DS.attr('boolean'),

  surveyIsEnabled: DS.attr('boolean'),
  surveyGamedayId: DS.attr('number'),
  surveyHubspotDealId: DS.attr('number'),

  weve_client_id: DS.attr('string'),

  tggClientId: DS.attr('string'),
  tggOverride: DS.attr('boolean', {defaultValue: false}),
  tggCode: DS.attr('string'),
  tggSyncedAt: DS.attr('date'),

  // vas url
  vasClientId: DS.attr('string'),
  weveGameUrl: DS.attr('string'),
  weveSyncedAt: DS.attr('date'),
  vasType: DS.attr('string'),

  statuses: {
    100: {name: 'closed', title: 'Closed'},
    200: {name: 'signup', title: 'Signup'},
    300: {name: 'started', title: 'Started'},
    400: {name: 'paused', title: 'Paused'},
    500: {name: 'ending', title: 'Ending'},
    600: {name: 'ended', title: 'Ended'},
    700: {name: 'voting', title: 'Voting'},
    800: {name: 'archived', title: 'Archived'}
  },

  timezone: function() {
    return this.get('game.city.timezone') || 'America/Los_Angeles';
  }.property('game.city.timezone'),

  hasStarted: Ember.computed.greaterThanOrEqualTo(
    'status', B.PlaythroughStatuses.STARTED),
  isStarted: Ember.computed.equals('status', B.PlaythroughStatuses.STARTED),
  isPaused: Ember.computed.equals('status', B.PlaythroughStatuses.PAUSED),
  isEnding: Ember.computed.equals('status', B.PlaythroughStatuses.ENDING),
  isEnded: Ember.computed.greaterThanOrEqualTo(
    'status', B.PlaythroughStatuses.ENDED),
  isEndingOrEnded: Ember.computed.greaterThanOrEqualTo(
    'status', B.PlaythroughStatuses.ENDING),

  isInProgress: function() {
    return this.get('status') === B.PlaythroughStatuses.STARTED ||
      this.get('status') === B.PlaythroughStatuses.ENDING;
  }.property('status'),
  isNotInProgress: Ember.computed.not('isInProgress'),
  isVoting: Ember.computed.equals('status', B.PlaythroughStatuses.VOTING),
  isStatusArchived: Ember.computed.equals('status',
    B.PlaythroughStatuses.ARCHIVED),

  startedAtLocal: Ember.computed.localtime('startedAt', 'timezone'),
  endedAtLocal: Ember.computed.localtime('endedAt', 'timezone'),
  endAtLocal: Ember.computed.localtime('endAt', 'timezone'),

  statusName: function() {
    return this.get('statuses')[this.get('status')].name;
  }.property('status'),

  statusTitle: function() {
    var statuses = this.get('statuses');
    var status = statuses[this.get('status')];
    return status ? status.title : 'N/A';
  }.property('status')
});

Breadcrumb.Player = Breadcrumb.Model.extend({
  playthrough: DS.belongsTo('playthrough'),
  email: DS.attr('string'),
  profile: DS.attr('obj')
});

Breadcrumb.Message = Ember.Object.extend({
  isFromTeam: Ember.computed.bool('fromTeamId'),
  isFromHq: Ember.computed.not('isFromTeam'),
  toJSON: function() {
    return {
      from_team_id: this.get('fromTeamId'),
      sent_at: this.get('sentAt'),
      message_type: this.get('messageType'),
      message_content: this.get('messageContent'),
      verify_text: this.get('verifyText'),
      read_at: this.get('readAt')
    };
  }
});

Breadcrumb.TeamStatuses = {
  CREATED: 100,
  START_TRACK: 200,
  IN_GAME: 300,
  GATED: 380,
  END_TRACK: 400,
  COMPLETED: 500
};

Breadcrumb.Team = Breadcrumb.Model.extend({
  playthrough: DS.belongsTo('playthrough'),
  name: DS.attr('string'),
  number: DS.attr('number'),
  track: DS.attr('number'),
  pin: DS.attr('string'),

  weve_conversation_team_id: DS.attr('string'),
  weve_conversation_org_id: DS.attr('string'),
  weve_conversation_game_id: DS.attr('string'),

  deviceId: DS.attr('string'),
  externalUserId: DS.attr('string'), //a user identifier from external integrations (i.e. Noodle)
  deviceName: DS.attr('string'),
  deviceInfo: DS.attr('obj'),
  devices: DS.hasMany('team_device', {inverse: null, async: true}),

  players: DS.attr('list'),  // list of logged in players' email & pw
  profile: DS.attr('obj'),
  socialInfo: DS.attr('obj'),
  history: DS.attr('list'),  // list of started&finished mission categories

  game_runner: DS.attr('boolean'),

  messages: DS.attr('messages'),
  challenges: DS.attr('obj'),

  pointsTotal: DS.attr('number'),
  pointsFromPlay: DS.attr('number'),
  pointsFromVoting: DS.attr('number'),

  lastLoggedInAt: DS.attr('date'),
  lastLocation: DS.attr('latlng'),
  lastLocationAccuracy: DS.attr('number'),
  lastLocatedAt: DS.attr('date'),

  status: DS.attr('number'),
  isArchived: DS.attr('boolean'),
  createdAt: DS.attr('date'),
  createdBy: DS.belongsTo('creator'),
  updatedAt: DS.attr('date'),
  updatedBy: DS.belongsTo('creator'),

  activeWaypoints: DS.hasMany('waypoint', {inverse: null, async: true}),

  hasStartedGame: Ember.computed.gte('status', Breadcrumb.TeamStatuses.START_TRACK),
  hasCompletedGame: Ember.computed.gte('status', Breadcrumb.TeamStatuses.COMPLETED),
  hasGatedOrCompletedGame: function() {
    return this.get('status') === Breadcrumb.TeamStatuses.GATED || this.get('status') === Breadcrumb.TeamStatuses.COMPLETED;
  }.property('status'),
});

Breadcrumb.TeamDevice = Breadcrumb.Model.extend({
  uuid: DS.attr('string'),
  team: DS.belongsTo('team'),
  available: DS.attr('boolean'),
  isVirtual: DS.attr('boolean'),
  appVersion: DS.attr('string'),
  name: DS.attr('string'),
  platform: DS.attr('string'),
  version: DS.attr('string'),
  model: DS.attr('string'),
  cordova: DS.attr('string'),
  serial: DS.attr('string'),
  manufacturer: DS.attr('string')
});

Breadcrumb.Waypoint = Breadcrumb.Model.extend({
  playthrough: DS.belongsTo('playthrough'),

  team: DS.belongsTo('team'),
  modeName: DS.attr('string'),

  mission: DS.belongsTo('mission'),
  stepNum: DS.attr('number'),
  stepId: DS.attr('number'),

  createdAt: DS.attr('date'),
  updatedAt: DS.attr('date'),

  socialInfo: DS.attr('obj'),
  hasShared: DS.attr('boolean'),

  queuedAt: DS.attr('date'),
  ungatedAt: DS.attr('date'),
  gatedUntil: DS.attr('date'),
  startedAt: DS.attr('date'),

  gatedByMission: DS.belongsTo('mission'),
  gatedByMissionDelay: DS.attr('string'),

  startedLocation: DS.attr('latlng'),
  completedAt: DS.attr('date'),
  completedLocation: DS.attr('latlng'),

  submissions: DS.attr('list'),
  numHintsUsed: DS.attr('number'),
  result: DS.attr('obj'),

  isJudgeable: DS.attr('boolean'),
  isVotable: DS.attr('boolean'),
  isVotingSelected: DS.attr('boolean'),

  votes: DS.attr('list'),

  hasMedia: DS.attr('boolean'),
  inGallery: DS.attr('boolean'),
  inSlideshow: DS.attr('boolean'),
  media: DS.attr('list'),

  points: DS.attr('number'),
  pointsAreOverridden: DS.attr('boolean'),

  timezone: Ember.computed.alias('team.playthrough.timezone'),

  queuedAtLocal: Ember.computed.localtime('queuedAt', 'timezone'),
  startedAtLocal: Ember.computed.localtime('startedAt', 'timezone'),
  completedAtLocal: Ember.computed.localtime('completedAt', 'timezone'),

  lastActivityAt: function() {
    if(!this.get('submissions.length')) { return null; }
    var time = this.get('submissions.lastObject.time');
    return DS.DateTransform.create().deserialize(time);
  }.property('submissions'),

  isCompleted: Ember.computed.bool('completedAt'),
  wasSuccessful: Ember.computed.equals('result.successful', true),
  wasUnsuccessful: Ember.computed.equals('result.successful', false),
  timedOut: Ember.computed.equals('result.timedout', true),
  wasSkipped: Ember.computed.equals('result.skipped', true),

  linkedTeams: DS.hasMany('team', {async: true}),
  linkedWaypoints: DS.hasMany('waypoint', {async: true}),
  isLinked: Ember.computed.bool('linkedTeams.length'),

  mode: function() {
    var modes = this.get('playthrough.game.routing.modes');
    if(!modes) { return null; }
    return modes.findBy('name', this.get('modeName'));
  }.property('modeName'),

  isPersistent: Ember.computed.equals('mode.type', 'persistent'),

  statusIcon: function() {
    if(!this.get('isCompleted')) { return '&hellip;'; }
    if(this.get('hasMedia')) { return '<i class="icon-camera"></i>'; }
    if(this.get('wasUnsuccessful')) { return '<i class="icon-remove"></i>'; }
    if(this.get('timedOut')) { return '<i class="icon-time"></i>'; }
    if(this.get('wasSkipped')) { return '<i class="icon-ellipsis-horizontal"></i>'; }
    return '<i class="icon-ok"></i>';
  }.property('completedAt', 'result'),

  statusTitle: function() {
    if(!this.get('isCompleted')) { return 'In progress'; }
    if(this.get('wasSuccessful')) { return 'Succeeded'; }
    if(this.get('wasUnsuccessful')) { return 'Failed'; }
    if(this.get('timedOut')) { return 'Timed out'; }
    if(this.get('wasSkipped')) { return "Skipped"; }
    return 'Completed';
  }.property('completedAt', 'result'),

  // find step first by stepId, then by stepNum.
  step: function() {
    var mission = this.get('mission');
    if(!mission || !mission.get('steps')) { return null; }
    var steps = mission.get('steps'), stepId = this.get('stepId');
    var step = stepId ? steps.findBy('stepId', stepId) : null;
    return step || steps.objectAt(this.get('stepNum') - 1);
  }.property('mission', 'stepNum'),

  stepName: function() {
    var name = this.get('mission.name');
    if(this.get('mission.hasMultipleSteps')) {
      name = '%@ %@/%@'.fmt(name, this.get('stepNum'),
        this.get('mission.steps.length'));
    }
    return name;
  }.property('mission', 'stepNum')

});


Breadcrumb.ClientLog = Breadcrumb.Model.extend({
  teamId: DS.attr('string'),
  entries: DS.attr('list'),
});


Breadcrumb.GamePlan = Breadcrumb.Model.extend({
  game: DS.belongsTo('game'),
  gamedayId: DS.attr('number'),
  dealId: DS.attr('number'),
  companyName: DS.attr('string'),
  clientName: DS.attr('string'),
  clientEmail: DS.attr('string'),
  gameDate: DS.attr('date'),
  gameTime: DS.attr('string'),
  startVenue: DS.attr('string'),
  endingVenue: DS.attr('string'),
  numPlayers: DS.attr('number'),
  numTeams: DS.attr('number'),
  portalId: DS.attr('number'),
  phone: DS.attr('string'),
  gameProducer: DS.attr('string'),
  idealGameRate: DS.attr('number'),
  missionDrinks: DS.attr('boolean'),

  copy: function(props) {
    props = $.extend({
      gamedayId: this.get('gamedayId'),
      companyName: this.get('companyName'),
      clientName: this.get('clientName'),
      clientEmail: this.get('clientEmail'),
      gameDate: this.get('gameDate'),
      gameTime: this.get('gameTime'),
      startVenue: this.get('startVenue'),
      endingVenue: this.get('endingVenue'),
      numPlayers: this.get('numPlayers'),
      numTeams: this.get('numTeams'),
      phone: this.get('phone'),
      gameProducer: this.get('gameProducer'),
    }, props || {});
    return this.store.createRecord('game-plan', props);
  }
});
