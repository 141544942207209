define('breadcrumb/components/time-since', ['exports', 'ember', 'breadcrumb/templates/components/time-since'], function (exports, _ember, _breadcrumbTemplatesComponentsTimeSince) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _breadcrumbTemplatesComponentsTimeSince['default'],
    tagName: '',
    format: 'human',

    time: _ember['default'].computed('Breadcrumb.ticker', 'input', function () {
      var format = this.get('format');
      var fn = Breadcrumb.Utils.timeFormatFns[format];
      return fn(this.get('input'), { relativeTimeType: 'since' });
    })
  });
});