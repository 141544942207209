Ember.Validators.ListValidator = Ember.Validator.extend({
  /** @private */
  _validateItem: function(obj, attr, value, item, idx) {},

  /** @private */
  _validate: function(obj, attr, value) {
    var errors = Ember.get(obj, 'validationErrors');
    if(!Ember.isArray(value)) {
      errors.add(attr, 'notList');
      return;
    }
    var options = Ember.get(this, 'options');
    if(!options.allowAnyBlank) {
      if(value.some(function(item) { return Ember.isEmpty(item); })) {
        errors.add(attr, 'hasEmpty');
        return;
      }
    }
    value.forEach(function(item, idx) {
      this._validateItem(obj, attr, value, item, idx);
    }, this);
  }
});

Ember.Validators.TextListValidator = Ember.Validators.ListValidator.extend({

});

Ember.Validators.TextAndCheckListValidator = Ember.Validators.ListValidator.extend({

  _validate: function(obj, attr, value) {
    this._super(obj, attr, value);
    var errors = Ember.get(obj, 'validationErrors'),
      options = Ember.get(this, 'options');
    if(options.requireOneChecked) {
      if(Ember.isArray(value)) {
        var oneIsChecked = false;
        value.forEach(function(item, idx) {
          if(item.checked) { oneIsChecked = true; }
        });
        if(!oneIsChecked) { errors.add(attr, 'noneChecked'); }
      }
    }
  },

  _validateItem: function(obj, attr, value, item, idx) {
    var errors = Ember.get(obj, 'validationErrors'),
      options = Ember.get(this, 'options');
    if(Ember.isEmpty(item.text)) {
      if(!errors.get('keys').contains('hasEmpty')) {
        errors.add(attr, 'hasEmpty');
      }
    }
  }
});

Ember.Validators.TextAndNumberListValidator = Ember.Validators.ListValidator.extend({

  _validate: function(obj, attr, value) {
    this._super(obj, attr, value);
    var errors = Ember.get(obj, 'validationErrors'),
      options = Ember.get(this, 'options');
    if(options.requireAllNumbers) {
      if(Ember.isArray(value)) {
        var oneHasNoNumber = false;
        value.forEach(function(item, idx) {
          if(!Breadcrumb.Utils.isNumber(item.number)){ oneHasNoNumber = true; }
        });
        if(oneHasNoNumber) { errors.add(attr, 'oneHasNoNumber'); }
      }
    }
  },

  _validateItem: function(obj, attr, value, item, idx) {
    var errors = Ember.get(obj, 'validationErrors'),
      options = Ember.get(this, 'options');
    if(Ember.isEmpty(item.text)) {
      if(!errors.get('keys').contains('hasEmpty')) {
        errors.add(attr, 'hasEmpty');
      }
    }
  }
});

Ember.Validators.TextAndValueListValidator = Ember.Validators.ListValidator.extend({

  _validate: function(obj, attr, value) {
    this._super(obj, attr, value);
    var errors = Ember.get(obj, 'validationErrors'),
      options = Ember.get(this, 'options');
  },

  _validateItem: function(obj, attr, value, item, idx) {
    var errors = Ember.get(obj, 'validationErrors'),
      options = Ember.get(this, 'options');
    if(Ember.isEmpty(item.text)) {
      if(!errors.get('keys').contains('hasEmpty')) {
        errors.add(attr, 'hasEmpty');
      }
    }
    if(Ember.isEmpty(item.value)) {
      if(!errors.get('keys').contains('hasEmpty')) {
        errors.add(attr, 'hasEmpty');
      }
    }
  }
});

Ember.ValidationError.addMessages({
  'notList': "is not a list",
  'hasEmpty': "has an empty value",
  'noneChecked': "no option is checked",
  'oneHasNoNumber': "at least one option has no number"
});
