define('breadcrumb/components/window-height-map', ['exports', 'ember-leaflet/components/leaflet-map', 'breadcrumb/mixins/adapts-to-window-height'], function (exports, _emberLeafletComponentsLeafletMap, _breadcrumbMixinsAdaptsToWindowHeight) {
  /**
   * Created by evanbabb on 3/4/16.
   */

  'use strict';

  exports['default'] = _emberLeafletComponentsLeafletMap['default'].extend(_breadcrumbMixinsAdaptsToWindowHeight['default'], {
    padding: 0,
    resizeSelectors: [],
    heightDefaults: { 'this': '350px' },

    resize: function resize() {
      this._super();
      this._layer.invalidateSize();
    }
  });
});