Breadcrumb.Alert = {

  delay: BREADCRUMB_ENV.TESTING ? 100 : 2000,

  error: function(message) {
    $(".error-overlay .error-content").html(message);
    $(".error-overlay").slideDown().delay(this.delay).slideUp();
    $(".error-overlay .error-overlay-close").click(function(){
      $(".error-overlay").hide();
    });

  },
  success: function(message) {
    $(".error-overlay .error-content").html(message);
    $(".error-overlay").slideDown().delay(this.delay).slideUp();
    $(".error-overlay .error-overlay-close").click(function(){
      $(".error-overlay").hide();
    });
  }
};

Breadcrumb.Loading = {
  show: function() {
    $(".loading-overlay").show();
  },
  hide: function() {
    $(".loading-overlay").fadeOut();
  }
};
