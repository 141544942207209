define('breadcrumb/mixins/adapts-to-window-height', ['exports', 'ember'], function (exports, _ember) {
  /**
   * Created by evanbabb on 3/4/16.
   */
  'use strict';

  exports['default'] = _ember['default'].Mixin.create({
    resizeSelectors: [],
    resizeSelf: true,
    padding: 0,
    ignoreAtWidthsLessThan: 767,
    heightDefaults: {},
    footerSelectors: {},
    minHeight: 100,

    didInsertElement: function didInsertElement() {
      this._super();
      this.resize();
      this.resizeHandler = Breadcrumb.bind(this.resize, this);
      $(window).on('resize', this.resizeHandler);
    },
    willDestroyElement: function willDestroyElement() {
      $(window).off('resize', this.resizeHandler);
      this._super();
    },
    selToEl: function selToEl(sel) {
      if (!sel) {
        return null;
      }
      if (sel === 'view') {
        return this.$();
      }
      return this.$(sel);
    },
    getFooterHeight: function getFooterHeight(sel) {
      var footerSelectors = this.get('footerSelectors'),
          footersHeight = 0;
      if (footerSelectors[sel]) {
        var $footerEl = this.$(footerSelectors[sel]);
        var footerHeight = $footerEl.outerHeight();
        footersHeight += footerHeight;
      }
      return footersHeight;
    },
    elForSel: function elForSel(sel) {
      return sel ? this.$(sel) : this.$();
    },
    getWindowHeight: function getWindowHeight() {
      return $(window).height();
    },
    getTop: function getTop(sel) {
      var $el = this.elForSel(sel);
      var offset = $el.offset() || {};
      return offset.top || 0;
    },
    getPadding: function getPadding(sel) {
      var padding = this.get('padding');
      if (_ember['default'].typeOf(padding) === 'object') {
        padding = padding[sel];
      }
      return padding || 0;
    },
    getAvailHeight: function getAvailHeight(sel) {
      var top = this.getTop(sel);
      var padding = this.getPadding(sel);
      var footers = this.getFooterHeight(sel);
      return this.getWindowHeight() - top - padding - footers;
    },
    shouldResize: function shouldResize() {
      return $(window).width() >= this.get('ignoreAtWidthsLessThan');
    },
    calculateElHeight: function calculateElHeight(sel) {
      var elHeight = this.getAvailHeight(sel);
      if (elHeight < this.minHeight) {
        elHeight = this.minHeight;
      }
      return elHeight;
    },
    getHeightDefault: function getHeightDefault(sel) {
      return this.heightDefaults[sel || 'this'] || 'inherit';
    },
    resizeSel: function resizeSel(sel) {
      var $el = this.elForSel(sel);
      if (!$el) {
        return;
      }
      if (this.shouldResize()) {
        $el.css('height', this.calculateElHeight(sel));
      } else {
        $el.css('height', this.getHeightDefault(sel));
      }
    },
    resize: function resize() {
      // Don't adjust window height while testing.
      if (BREADCRUMB_ENV.TESTING) {
        return;
      }
      if (this.get('resizeSelf')) {
        this.resizeSel(null);
      }
      this.get('resizeSelectors').forEach(function (sel) {
        this.resizeSel(sel);
      }, this);
    }
  });
});