
var LoadableMixin = Ember.Mixin.create({
  load: function(content) {
    if(!this.get('content')) { this.set('content', content); }
    if(!content.then) { return; }
    var controller = this;
    this.setProperties({isLoading: true, loadingContent: content});
    return content.then(function() {
      if(controller.get('loadingContent') !== content) { return; }
      controller.setProperties({
        content: content,
        isLoading: false,
        loadingContent: null
      });
    }, function(err) {
      // error
      if(controller.get('loadingContent') !== content) { return; }
      controller.setProperties({isLoading: false, loadingContent: null});
      throw err;
    });
  }
});

Ember.Application.initializer({
  name: 'loadable',
  initialize: function() {
    Ember.ArrayController.reopen(LoadableMixin);
    Ember.GroupingArrayController.reopen(LoadableMixin);
  }
});
