
Breadcrumb.Logs = {
  store: undefined,
  reset: function() {
    Breadcrumb.localStorage.clear('breadcrumb-log-entries');
  },
  join: function(args) {
    // allow any number of arguments to logger
    args = Array.prototype.slice.call(args);
    return (args.length > 1) ? args.join(' ') : args[0]; 
  },
  _log: function(msg, level) {
    if(BREADCRUMB_ENV.ENVIRONMENT_NAME !== 'test') { console[level](msg); }
    this.addEntry(msg, 'log');
  },
  log: function() {
    this._log(this.join(arguments), 'log');
  },
  warn: function() {
    this._log(this.join(arguments), 'warn');
  },
  error: function() {
    this._log(this.join(arguments), 'error');
  },
  addEntry: function(msg, level) {
    if(! Breadcrumb.localStorage.test()) {
      console.warn('localStorage and logging unavailable.');
      return;
    }
    var entry = {
      timestamp: new Date(),
      level: level || 'log',
      msg: msg,
    },
    ls = Breadcrumb.localStorage,
    entries = ls.getJson('breadcrumb-log-entries') || [];
    entries.push(entry);
    ls.setJson('breadcrumb-log-entries', entries);
  },
  save: function(teamId) {
    teamId = teamId || Breadcrumb.localStorage.get('breadcrumb-save-team-id');
    if(!teamId) { 
      console.error('Log save requires a team id.'); 
      return;
    }
    var entries = Breadcrumb.localStorage.getJson('breadcrumb-log-entries');
    if(!entries) { 
      console.warn('No log entries found. Aborting save.');
      return; 
    }
    var store = this.store;
    if(!store) {
      console.error('Store is undefined. Unable to save logs.'); 
      return;          
    }
    var self = this;
    var clientLog = store.createRecord('clientLog', {
      teamId: teamId,
      entries: entries
    }).save().then(function() {
      self.reset();
    });
  }
};
