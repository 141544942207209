Breadcrumb.Media = {};

Breadcrumb.Media.getMediaFolder = function(mediaItem) {
  if(BREADCRUMB_ENV.PRESO_IS_LOCAL) {
    return BREADCRUMB_ENV.PRESO_LOCAL_MEDIA_PATH;
  }
  if(BREADCRUMB_ENV.TESTING) {
    return '/static/test/media';
  }
  var bucketName = mediaItem.media_bucket;
  return 'https://%@.s3.amazonaws.com/media'.fmt(bucketName);
};

Breadcrumb.Media.getThumbnailUrl = function(mediaItem, size) {
  size = typeof size !== 'undefined' ? size : 'tiny';
  if(!mediaItem.is_uploaded) {
    if(!mediaItem.is_uploading) {
      return '/static/images/pending.png';
    }
    return '/static/images/uploading.png';
  }
  if(!mediaItem.is_ready) { return '/static/images/processing.png'; }
  var key = mediaItem.media_key;
  if(!key || key.trim() === "") { return '/static/images/nothumbnail.png'; }

  var mediaFolder = Breadcrumb.Media.getMediaFolder(mediaItem);
  var rotated_at = mediaItem.rotated_at ? '?'+mediaItem.rotated_at : '';
  if(mediaItem.media_type === 'image') {
    return '%@/%@%@'.fmt(mediaFolder, key.replace('<size>', size), rotated_at);
  } else if(mediaItem.media_type === 'video') {
    var keyParts = key.split('/');
    keyParts[keyParts.length - 2] += '/video-thumbnails';
    var thumbnailKey = (keyParts.join("/")
      .replace('.mp4', '-<size>-<num>.jpg')
      .replace('<size>', 'tiny')
      .replace('<num>', 0));
    return '%@/%@%@'.fmt(mediaFolder, thumbnailKey, rotated_at);
  }
};

Breadcrumb.Media.getVideoUrl = function(mediaItem) {
  if(mediaItem.media_type !== 'video' || !mediaItem.is_uploaded ||
    !mediaItem.is_ready) { return null; }
  var key = mediaItem.media_key;
  if(!key || key.trim() === "") { return null; }
  var folder = Breadcrumb.Media.getMediaFolder(mediaItem);
  return '%@/%@'.fmt(folder, key);
};

Breadcrumb.Media.getWatermarksFolder = function(mediaItem, isGallery) {
  if(BREADCRUMB_ENV.PRESO_IS_LOCAL) {
    return BREADCRUMB_ENV.PRESO_LOCAL_MEDIA_PATH;
  }
  var bucketName = mediaItem.media_bucket;
  var folder = isGallery ? 'watermarks' : 'media';
  return 'https://%@.s3.amazonaws.com/%@'.fmt(bucketName, folder);
};

Breadcrumb.Media.getWatermarkedThumbnailUrl = function(mediaItem, size, isGallery) {
  size = typeof size !== 'undefined' ? size : 'tiny';
  var iconsPath = '/static/images/';
  var ext = '.png'
  var thumbClass = Breadcrumb.Media.getThumbnailClass(mediaItem, isGallery);
 // console.log(thumbClass);
  if(thumbClass !== ''){
    return iconsPath + thumbClass + ext;
  }
  if(mediaItem.media_type === 'image') {
    var mediaFolder = Breadcrumb.Media.getWatermarksFolder(mediaItem, isGallery);
    return '%@/%@'.fmt(mediaFolder, mediaItem.media_key.replace('<size>', size));
  } else if(mediaItem.media_type === 'video') {
    var mediaFolder = Breadcrumb.Media.getMediaFolder(mediaItem);
    var keyParts = mediaItem.media_key.split('/');
    keyParts[keyParts.length - 2] += '/video-thumbnails';
    var thumbnailKey = keyParts.join("/").replace('.mp4', '-' + size + '-0.jpg')
    return '%@/%@'.fmt(mediaFolder, thumbnailKey);
  }
};

Breadcrumb.Media.notWatermarked = function(mediaItem){
  return mediaItem.media_type === 'image' && mediaItem.watermarked !== true;
};

Breadcrumb.Media.photoExists = function(mediaItem){
  return mediaItem && mediaItem.media_key && mediaItem.media_key.trim() !== "";
};

Breadcrumb.Media.getThumbnailClass = function(mediaItem, isGallery) {
  //console.log(mediaItem);
  if(!mediaItem || !mediaItem.media_key || mediaItem.media_key.trim() === ""){
    return 'nothumbnail';
  }
  if (!mediaItem.is_ready || (isGallery && Breadcrumb.Media.notWatermarked(mediaItem))) {
    return 'processing';
  }
  if (!mediaItem.is_uploaded && !mediaItem.is_uploading) {
    return 'pending';
  }
  if (!mediaItem.is_uploaded) {
    return 'uploading';
  }
  return '';
};
