Breadcrumb.NativeHooks = {
  init: function(){
  },
  getAppVersion: function(){
    return BREADCRUMB_ENV.DISPLAY_VERSION
  },
  getAppBuild: function(){
    return ''
  },

  watchLocation: function(success, error) {
    if(!navigator.geolocation) { error({code: 'NO_GEOLOCATION'}); return; }
    var options = {enableHighAccuracy: true, maximumAge: 1 * 60000};
    return navigator.geolocation.watchPosition(success, error, options);
  },

  unwatchLocation: function(watchId) {
    navigator.geolocation.clearWatch(watchId);
  },

  getCurrentLocation: function(success, error) {
    if(!navigator.geolocation) { error({code: 'NO_GEOLOCATION'}); return; }
    var options = {enableHighAccuracy: true, timeout: 0};
    navigator.geolocation.getCurrentPosition(success, error, options);
  },

  upload: function(url, params, file, success, error, progress) {
    AWS.config.accessKeyId = params.awsaccesskeyid;
    AWS.config.secretAccessKey = params.skey;
    var bucket = new AWS.S3({
      httpOptions: {timeout: 0},
      params: {
        Bucket: params.bucket
      }
    });
    var aws_params = {
      Key: params.key,
      ContentType: file.type,
      Body: file,
      ACL: params.acl
    };

    request = bucket.putObject(aws_params);
    request.on("httpUploadProgress", progress);
    request.on("success", success);
    request.on("error", error);
    request.send();
    return request;
  },

  abortUpload: function(xhr) {
    xhr.abort();
  },

  getUpdateAvailability: function() {
    return Ember.RSVP.resolve(false);
  },

  logging: console,

  getDeviceId: function(){
    return Ember.RSVP.resolve(null);
  },

  setDeviceId: function(deviceId){
    return;
  },

  getDeviceInfo: function(){
    var device = {
      platform: "web",
      name: false
    }
    return Ember.RSVP.resolve(device);
  },

  useNativeMedia: function () {
    return false;
  },
  getMedia: function(success, error) { error("not implemented"); },
  getVideo: function(success, error) { error("not implemented"); },
  getImage: function(success, error) { error("not implemented"); },
  useQrCodes: function() { return false; },
  getQrCode: function(success, error) { error("not implemented"); },

  prompt: function (message, cb) {
    var pw = Breadcrumb.Utils.prompt(message);
    cb(pw);
  },

  confirm: function (message, title, yesCb, noCb) {
    var res = Breadcrumb.Utils.confirm(message);
    if (res){
      yesCb();
    } else{
      noCb();
    }
    return res;
  },



};

Breadcrumb.NativeHooks.Utils = {};

Breadcrumb.NativeHooks.Utils.waitForSocialResponse = function(url){
  var win = window.open(url);
  win.focus();
  return new Ember.RSVP.Promise(function(resolve){
    var timer = setInterval(function() {
      if(win.closed) {
          clearInterval(timer);
          resolve();
      }
    }, 1000);
  });
};

Breadcrumb.NativeHooks.Social = {};

Breadcrumb.NativeHooks.Social.Facebook = {
  isAvailable: false,
  login: function(teamId){
    var url = "/social/authorize/facebook/" + teamId;
    return Breadcrumb.NativeHooks.Utils.waitForSocialResponse(url);
  }
};

Breadcrumb.NativeHooks.Social.Twitter = {
  isAvailable: true,
  login: function(teamId){
    var url = "/social/authorize/twitter/" + teamId;
    return Breadcrumb.NativeHooks.Utils.waitForSocialResponse(url);
  }
};

Breadcrumb.NativeHooks.Social.Linkedin = {
  isAvailable: true,
  login: function (teamId) {
    var url = "/social/authorize/linkedin/" + teamId;
    return Breadcrumb.NativeHooks.Utils.waitForSocialResponse(url);
  }
};


