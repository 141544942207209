Breadcrumb.now = function(t) {
  return t ? new Date(t) : new Date();
};

Breadcrumb.nowPacific = function() {
  return Breadcrumb.toPacificTime(Breadcrumb.now());
};

Breadcrumb.toPacificTime = function(t) {
  return Breadcrumb.toUtcOffset(t, Breadcrumb.getPacificTimeOffset(t));
};

Breadcrumb.getPacificTimeOffset = function(t) {
  return Breadcrumb.isDst(t) ? -7 : -8;
};

Breadcrumb.toUtcOffset = function(t, utcOffset) {
  var pstOffset = utcOffset * 3600 * 1000;
  return new Date(t.getTime() + pstOffset);  
};

Breadcrumb.stdTimezoneOffset = function(t) {
  var jan = new Date(t.getFullYear(), 0, 1);
  var jul = new Date(t.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
};

Breadcrumb.isDst = function(t) {
    return t.getTimezoneOffset() < Breadcrumb.stdTimezoneOffset(t);
};
