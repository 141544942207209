Breadcrumb.LocalStorage = {
  test: function() {
    if(typeof localStorage === "undefined" || !localStorage) { return false; }
    try {
      localStorage.setItem('__test__', '__test__');
      localStorage.removeItem('__test__');
      return true;
    } catch(err) {
      return false;
    }
  },
  get: function(key) {
    try {
      return localStorage[key];
    } catch(err) {
      console.error("Could not retrieve data from local storage.");
      return null;
    }
  },
  getInt: function(key) {
    var value = this.get(key);
    return value ? parseInt(value, 10) : null;
  },
  getJson: function(key) {
    var value = this.get(key);
    return value ? JSON.parse(value) : null;
  },
  set: function(key, data) {
    try {
      localStorage[key] = data;
    } catch(err) {
      console.error("Could not save data to local storage.");
    }
  },
  setJson: function(key, data) {
    this.set(key, JSON.stringify(data));
  },
  clear: function(key) {
    try {
      delete localStorage[key]; 
    } catch(err) {
      console.error("Could not delete data from local storage.");
    }
  },
  setMany: function(attrs) {
    Object.keys(attrs).forEach(function(key) {
      this.set(key, attrs[key]);      
    }, this);
  },
  clearMany: function(keys) {
    keys.forEach(function(key) { this.clear(key); }, this);
  }
};
