Breadcrumb.Retryable = Ember.Object.extend({

  initialRetryBackoff: 1000,
  maxRetryBackoff: 5000,
  maxAttempts: 5,
  retryAt: null,
  retryIntervalId: null,

  isInProgress: false,
  isActing: false,
  isWaitingToRetry: false,
  isRetrying: false,
  isError: false,
  isFailure: false,
  isCompleted: false,

  init: function() {
    this._super();
    Ember.assert("Retryable must have an action.", !!this.action);
    Ember.assert("Action must be a function.",
      typeof this.action === 'function');
    this.deferred = Ember.RSVP.defer();
  },

  destroy: function() {
    this.cancel();
    return this._super();
  },

  start: function() {
    if(this.get('isCompleted') || this.get('isInProgress')) {
      throw new Error("Cannot start a Retryable more than once."); }
    if(!this.deferred || this.deferred.promise.isRejected) {
      this.deferred = Ember.RSVP.defer(); }
    this.setProperties({
      isInProgress: true,
      isActing: true,
      isError: false,
      isFailure: false,
      numAttempts: 1
    });
    this.act();
    return this;
  },

  restart: function() {
    if(!this.get('isFailure') && !this.get('isInProgress')) {
      throw new Error(
        "Cannot restart a Retryable that is not failed or in progress"); }
    if(this.retryIntervalId) { this.cancel(); }
    if(!this.deferred || this.deferred.promise.isRejected) {
      this.deferred = Ember.RSVP.defer(); }
    this.setProperties({
      isInProgress: true,
      isActing: true,
      isRetrying: false,
      isWaitingToRetry: false,
      isError: false,
      isFailure: false,
      isCompleted: false,
      numAttempts: 1
    });
    this.act();
    return this;
  },

  then: function(resolve, reject) {
    Ember.assert("Must have deferred.", !!this.deferred);
    return this.deferred.promise.then(resolve, reject);
  },

  retry: function() {
    this.retryIntervalId = null;
    this.setProperties({
      retryAt: null,
      isWaitingToRetry: false,
      isActing: true
    });
    this.act();
  },

  act: function() {
    var result = this.action.call(this.context), deferred = this.deferred;
    if(!result || !result.then) { // we are not a promise; resolve with value
      if(this.deferred !== deferred) { return; } // had been restarted
      this.onResolve(result);
    } else { // returned a promise, so execute.
      result.then(
        B.bind(function(value) {
          // var t1 = new Date().getTime();
          if(this.deferred !== deferred) { return; } // had been restarted
          this.onResolve(value);
        }, this),
        B.bind(function(err) {
          if(this.deferred !== deferred) { return; } // had been restarted
          this.onReject(err);
        }, this)
      );
    }
  },

  cancel: function() {
    if(this.retryIntervalId) {
      clearTimeout(this.retryIntervalId);
      this.retryIntervalId = null;
    }
    this.setProperties({
      retryAt: null,
      isWaitingToRetry: false,
      isInProgress: false
    });
  },

  onResolve: function(value) {
    this.setProperties({
      isInProgress: false,
      isActing: false,
      isRetrying: false,
      isError: false,
      isWaitingToRetry: false,
      isCompleted: true
    });
    this.deferred.resolve(value);
    this.deferred = null;
  },

  retryBackoffForAttemptNum: function(attemptNum) {
    var initialBackoff = this.get('initialRetryBackoff');
    var backoff = initialBackoff * Math.pow(2, (attemptNum - 1));
    return backoff < this.get('maxRetryBackoff') ? backoff : this.get('maxRetryBackoff');
  },

  onReject: function() {
    // Are we final rejection?
    if(this.get('maxAttempts')!==false && this.get('numAttempts') >= this.get('maxAttempts')) {
      this.setProperties({
        isInProgress: false,
        isActing: false,
        isRetrying: false,
        isWaitingToRetry: false,
        isError: true,
        isFailure: true
      });
      this.deferred.reject(new Error({
        message: "Attempt " + this.get('numAttempts') +
        " failed."}));
      this.deferred = null;
      return;
    }
    // If not, retry.
    var numAttempts = this.get('numAttempts'),
      retryBackoff = this.retryBackoffForAttemptNum(numAttempts);
    var retryAt = new Date(new Date().getTime() + retryBackoff);
    var props = {
      numAttempts: numAttempts + 1,
      retryAt: retryAt,
      isError: true,
      isRetrying: true,
      isWaitingToRetry: true,
      isActing: false
    };
    this.setProperties(props);
    this.retryIntervalId = setTimeout(
      B.bind(this.retry, this), retryBackoff);
  }
});

Breadcrumb.retry = function(options, action, context) {
  var opts = options;
  if(typeof options === 'function') { // action, context, [options]
    opts = arguments[2] || {};
    opts.action = arguments[0];
    opts.context = arguments[1];
  } // otherwise, called with {options}, no changes necessary
  return Breadcrumb.Retryable.create(opts).start();
};
