Breadcrumb.Application = Ember.Application.extend({
  init: function() {
    this.ApplicationAdapter = Breadcrumb.Adapter;
    this.ApplicationSerializer = Breadcrumb.Serializer;
    this.ApplicationStore = Breadcrumb.Store;
    // copy over models, adapters, and serializers.
    var copyClasses = [DS.Model, DS.Adapter, DS.JSONSerializer,
      Ember.Component];
    copyClasses.forEach(function(cls) {
      for(var key in Breadcrumb) {
        if(cls.detect(Breadcrumb[key])) { this[key] = Breadcrumb[key]; }
      }
    }, this);
    return this._super();
  }
});

Breadcrumb.ApplicationController = Ember.Controller.extend({
  lastTick: null,
  resumeThreshold: 1 * 60 * 1000, // a minute
  tickerDidUpdate: function() {
    var lastTick = this.get('lastTick') || Breadcrumb.now();
    var timeSinceLastTick = Breadcrumb.now().getTime() - lastTick.getTime();
    if(timeSinceLastTick > this.get('resumeThreshold')) {
      this.get('target').send('resume');
    }
    this.set('lastTick', Breadcrumb.now());
  }.observes('Breadcrumb.ticker')
});
