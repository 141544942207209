
Breadcrumb.Pin = {
  /*
  Pins are server generated by utils/utils.py - generate_pin()
  because they must be checked against db queries for availability.
  Pins are randomly generated strings.
  * Note that pin length needs to be coupled here and in utils.py
  Pins contain only lowercase letters in the range a-z.
  A breadcrumb -loginCode- may consist of one, two or three parts:
  Part one: playthrough pin
  Part two: team pin
  Part three: optional role code (defaults to 'a' if not present)
  * Role codes may be any single character, enabling a max of 26 roles
  * but in practice we use only a=main (default) and b=bonus_camera.
  */

  alphabet: 'abcdefghijklmnopqrstuvwxyz',
  length: 3, // must match utils/utils.py - generate_pin length

  isNumeric: function(pin) {
    // TODO: deprecate numeric pins
    return /^[0-9]+$/.test(pin);
  },
  getRoleCode: function(roleIndex, playthroughPin) {
    var roleCode;
    if(this.isNumeric(playthroughPin)) {
      // TODO : deprecate numeric pins
      roleCode = (roleIndex + 1) * 3;
    } else {
      // if role is default (0), no need to include it
      roleCode = roleIndex > 0 ? this.alphabet[roleIndex] : '';
    }
    return roleCode;
  },
  getRoleIndex: function(roleCode) {
    return this.alphabet.indexOf(roleCode);
  },
  getPlaythroughPin: function(pin) {
    return pin.substr(0, this.length);
  },
  getTeamPin: function(pin) {
    return pin.substr(this.length, this.length);
  },
  getRoleIndexFromPin: function(pin) {
    // do not allow logging in as bonus cam without a team pin
    var roleCode = pin.substr((this.length * 2), 1) || 'a';
    return this.getRoleIndex(roleCode);
  },
  getTeamLogin: function(playthroughPin, teamPin, roleIndex) {
    var roleCode = this.getRoleCode(roleIndex || 0, playthroughPin);
    return playthroughPin + teamPin + roleCode;
  },
  validatePin: function(pin) {
    return (
      pin.length === this.length ||  // playthrough
      pin.length === this.length * 2 || // playthrough, team
      pin.length === this.length * 2 + 1) && // playthrough, team, role
      /^[a-z]+$/.test(pin); // only a-z
  }

};
