Breadcrumb.Views.TruncatedTextView = Ember.View.extend({
  length: 300,
  classNames: ['condensed'],

  didInsertElement: function() {
    // TRUNCATED TEXT VIEW REMOVED FOR NOW
  },
  
  template: Ember.Handlebars.compile('{{{view.content}}}')
});

Breadcrumb.Views.TruncatedMarkdownView = (Breadcrumb.Views
    .TruncatedTextView.extend({
  template: Ember.Handlebars.compile('{{{markdown view.content}}}')
}));

