
// usage: {{s3-upload value=value bucket='gogame-bucket' folder='folder'}}
// uploaded files' returned url will be set to value on parent controller

Breadcrumb.S3UploadComponent = Ember.Component.extend({
  accept: '.jpg, .jpeg, .png, .gif',
  bucket: 'gogame-%@', // need to set this to actual bucket name
  classNames: ['s3-upload-component'],
  _deferred: null,
  folder: '',
  file: null,
  isUploading: false,
  mimeTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif'],
  percent: 0,
  signature: null,
  signPromise: null,
  type: 'POST',
  url: 'https://%@.s3.amazonaws.com',
  value: '', // visible text input val bound to controller
  xhr: null,
  max_upload_size: 500000000,

  _sign: function() {
    var bucket = this.get('bucket'),
    folder = this.get('folder'),
    file = this.get('file'),
    key = (folder ? folder + '/' : '') + file.name,
    signUrl = '/sign?bucket=' + encodeURIComponent(bucket) +
      '&key=' + encodeURIComponent(key);

    return $.getJSON(Breadcrumb.api.siteUrl(signUrl));
  },

  validate: function() {
    var file = this.get('file');
    if(!file) {
      // no error because file select dialog was prob cancelled
      return false;
    }
    if(file.size > this.get('max_upload_size')) {
      this.showError('File is too big. Max = 500MB');
      return false;
    }
    if(this.get('mimeTypes').indexOf(file.type) === -1) {
      this.showError('Unacceptable file type.\n' +
        'Acceptable types: jpg, jpeg, png, gif');
      return false;
    }
    return true;
  },

  // computed props
  fmtUrl: function() {
    return this.get('url').fmt(this.get('bucket'));
  }.property('url', 'bucket'),

  params: function() {
    var params = {},
    folder = this.get('folder'),
    file = this.get('file');
    $.extend(params, this.get('signature'));
    $.extend(params, {
      'bucket': this.get('bucket'),
      'key': (folder ? folder + '/' : '') + file.name,
      'content-type': file.type
    });
    return params;
  }.property('bucket', 'folder', 'file', 'signature'),

  // on handlers
  progress: function(e) {
    this.set('percent', Math.floor(100 * (e.loaded || e.done) / e.total));
  },

  success: function(r) {
    uploadedUrl = 'https://%@.s3.amazonaws.com/%@'.fmt(r.request.params.Bucket, r.request.params.Key);
    this.set('value', uploadedUrl);
    this.set('isUploading', false);
    this._deferred.resolve();
  },

  fail: function(r) {
    this.showError('Upload failure.');
    this.set('isUploading', false);
    this._deferred.reject();
  },

  change: function(e) {
    var input = e.target;
    if(input.type === 'text') {
      // content set manually from text input
      this.set('value', $(input).val());
      $(input).blur();
      return;
    }
    if(input.type === 'file') {
      this.set('file', (window.mockedFiles || input.files)[0]);
      if(!this.validate()) { return; }
      this.set('isUploading', true);
      return this.upload();
    }
  },

  upload: function() {
    this._deferred = Ember.RSVP.defer();
    var self = this;

    this._sign().then(function(signature) {
      self.set('signature', signature);
      self.set('xhr', Breadcrumb.NativeHooks.upload(
        self.get('fmtUrl'),
        self.get('params'),
        self.get('file'),
        function success(e) { Ember.run(self, 'success', e); },
        function fail(e) { Ember.run(self, 'fail', e); },
        function progress(e) { Ember.run(self, 'progress', e); }
      ));
    }, function() {
      self.showError('Error setting signature.');
    });
    return this._deferred.promise;
  },

  showError: function(msg) {
    Breadcrumb.Alert.error(msg);
    this.set('isUploading', false);
  }
});
