Breadcrumb.Views.PreserveImgAspectRatio = Ember.Mixin.create({
  imgSelectors: [],

  didInsertElement: function() {
    this._super();
    this.preserve();
    this.preserveHandler = B.bind(this.preserve, this);
    $(window).on('resize', this.preserveHandler);
  },
  willDestroyElement: function() {
    $(window).off('resize', this.preserveHandler);
    this._super();
  },
  preserveImg: function(sel) {
    var img = $(sel)[0];
    if(!img) { return; }
    var parent = $(img).parent()[0];
    $(img).css('max-width', 'none');
    $(img).css('max-height', 'none');

    if($(parent).width() !== 0 && $(parent).width() < img.width){
      img.style.width = "100%";
      img.style.height = "auto";
    } else if($(parent).height() < img.height){
      img.style.height = "100%";
      img.style.width = "auto";
    }
  },
  preserve: function() {
    // Don't adjust img while testing.
    if(BREADCRUMB_ENV.TESTING) { return; }

    Ember.run.next(this, function() {
      this.get('imgSelectors').forEach(function(sel) {
        this.preserveImg(sel);
      }, this);
    });
  }
});
