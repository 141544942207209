var get = Ember.get,
  forEach = Ember.EnumerableUtils.forEach,
  fmt = Ember.String.fmt;

Ember.GroupingArrayProxy = Ember.Object.extend(Ember.Array, {
  content: null,
  groupProperty: null,

  groupsArrayClass: Ember.ArrayProxy,
  groupArrayClass: Ember.ArrayProxy,

  objectAt: function(idx) {
    return get(this, 'content') && get(this, 'groups').objectAt(idx);
  },

  length: Ember.computed(function() {
    var groups = get(this, 'groups');
    return groups ? get(groups, 'length') : 0;
  }).volatile(),

  replace: function(idx, amt, objects) {
    throw new Error("Not implemented.");
  },

  createGroupsArray: function() {
    var groupsArrayClass = get(this, 'groupsArrayClass');
    return groupsArrayClass.create({content: Ember.A()});
  },

  createGroupArray: function(groupVal) {
    var groupArrayClass = get(this, 'groupArrayClass');
    return groupArrayClass.create({content:Ember.A(), group: groupVal});
  },

  addGroupArray: function(groupVal) {
    var groups = get(this, 'groups');
    var group = this.createGroupArray(groupVal);
    get(groups, 'content').pushObject(group);
    return group;
  },

  removeGroupArray: function(groupVal) {
    var group = this.groupForValue(groupVal);
    get(this, 'groups.content').removeObject(group);
    group.destroy();
  },

  groups: Ember.computed('content', 'groupProperty', function() {
    var content = get(this, 'content'),
      groupProperty = get(this, 'groupProperty'),
      groups = null, group = null, groupVal = null;

    Ember.assert("Must supply a `groupProperty` value.",
      !!get(this, 'groupProperty'));

    // Return a new empty array for each group.
    groups = this.createGroupsArray();
    if (!content) { return groups; }
    forEach(content, function(item) {
      groupVal = get(item, groupProperty);
      group = groups.filterBy('group', groupVal)[0];
      if(!group) {
        group = this.createGroupArray(groupVal);
        get(groups, 'content').pushObject(group);
      }
      get(group, 'content').pushObject(item);
    }, this);

    groups.addArrayObserver(this, {
      willChange: 'groupsContentWillChange',
      didChange: 'groupsContentDidChange'
    });

    return groups;
  }),

  _groupsWillChange: Ember.beforeObserver(function() {
    // destroy groups object observers
    if(!get(this, 'groups')) { return; }
    get(this, 'groups').removeArrayObserver(this, {
      willChange: 'groupsContentWillChange',
      didChange: 'groupsContentDidChange'
    });
  }, 'groups'),

  // forward array observer events
  groupsContentWillChange: function(array, startIdx, removeAmt, addAmt) {
    this.arrayContentWillChange(startIdx, removeAmt, addAmt);
  },

  groupsContentDidChange: function(array, startIdx, removeAmt, addAmt) {
    this.arrayContentDidChange(startIdx, removeAmt, addAmt);
  },

  groupContainingItem: function(item) {
    var groups = get(this, 'groups');
    var groupsContainingItem = groups.filter(function(g) {
      return g.get('content').contains(item);
    });
    Ember.assert(fmt("Item must not be in more than one group, was in %@.",
      [get(groupsContainingItem, 'length')]),
      get(groupsContainingItem, 'length') <= 1);
    return groupsContainingItem[0];
  },

  groupValues: Ember.computed(function() {
    return get(this, 'groups').mapBy('group');
  }).volatile(),

  groupForValue: function(groupVal) {
    return get(this, 'groups').filterBy('group', groupVal)[0];
  },

  contentArrayWillChange: function(array, idx, removedAmt, addedAmt) {
    var groupProperty = get(this, 'groupProperty');
    var removedObjects = array.slice(idx, idx + removedAmt);
    forEach(removedObjects, function(item) {
      this.removeObject(item);
      Ember.removeObserver(item, groupProperty, this,
        'contentItemGroupPropertyDidChange');
    }, this);
  },

  contentArrayDidChange: function(array, idx, removedAmt, addedAmt) {
    var groupProperty = get(this, 'groupProperty');
    var addedObjects = array.slice(idx, idx + addedAmt);
    forEach(addedObjects, function(item) {
      this.insertItemGrouped(item);
      Ember.addObserver(item, groupProperty, this,
        'contentItemGroupPropertyDidChange');
    }, this);
  },

  contentItemGroupPropertyDidChange: function(item) {
    this.updateItemGroup(item);
  },

  removeObject: function(item) {
    var group = this.groupContainingItem(item);
    if (!group) { return; }
    get(group, 'content').removeObject(item);
    if(get(group, 'length') === 0) {
      this.removeGroupArray(group.get('group'));
    }
  },

  updateItemGroup: function(item) {
    var groups = get(this, 'groups'),
      oldGroup = this.groupContainingItem(item),
      groupProperty = get(this, 'groupProperty'),
      groupVal = get(item, groupProperty),
      group = groupVal ? groups.filterBy('group', groupVal)[0] : null;

    // Ember.assert("Item must already be in a group.", !!oldGroup);
    // Ember.assert("Item must be entering a group.", !!group);

    if (group === oldGroup) { return; }
    if(oldGroup) { this.removeObject(item); }
    if(groupVal) { this.insertItemGrouped(item); }
  },

  insertItemGrouped: function(item) {
    var groups = get(this, 'groups'),
      oldGroup = this.groupContainingItem(item),
      groupProperty = get(this, 'groupProperty'),
      groupVal = get(item, groupProperty),
      group = groups.filterBy('group', groupVal)[0];

    Ember.assert("Item must not already be in a group.", !oldGroup);

    // only add to the set if we have a group value.
    if (!groupVal) { return; }
    if (!group) {
      group = this.addGroupArray(groupVal);
    }
    get(group, 'content').pushObject(item);
  },

  _contentWillChange: Ember.beforeObserver(function() {
    this._teardownContent();
  }, 'content'),

  _teardownContent: function() {
    var content = get(this, 'content'),
      groupProperty = get(this, 'groupProperty');
    if (content) {
      forEach(content, function(item) {
        Ember.removeObserver(item, groupProperty, this,
          'contentItemGroupPropertyDidChange');
      }, this);
      content.removeArrayObserver(this, {
        willChange: 'contentArrayWillChange',
        didChange: 'contentArrayDidChange'
      });
    }
  },

  _contentDidChange: Ember.observer(function() {
    var content = get(this, 'content');
    Ember.assert("Can't set GroupingArrayProxy's content to itself",
      content !== this);
    this._setupContent();
  }, 'content'),

  _setupContent: function() {
    var content = get(this, 'content'),
      groupProperty = get(this, 'groupProperty');
    if (content) {
      get(this, 'groups'); // init groups
      content.addArrayObserver(this, {
        willChange: 'contentArrayWillChange',
        didChange: 'contentArrayDidChange'
      });
      forEach(content, function(item) {
        Ember.addObserver(item, groupProperty, this,
          'contentItemGroupPropertyDidChange');
      }, this);
    }
  },

  init: function() {
    this._super();
    this._setupContent();
  },

  willDestroy: function() {
    this._teardownContent();
    this.get('groups').destroy();
    this._super();
  }
});

Ember.GroupingArrayController = Ember.GroupingArrayProxy.extend(
    Ember.ControllerMixin, {

  groupsController: null,
  groupController: null,

  subContainers: null,

  init: function() {
    this._resetSubContainers();
    this._super();
    Ember.assert('a groupsController is required.',
      !!this.get('groupsController'));
    Ember.assert('a groupController is required.',
      !!this.get('groupController'));
  },

  _teardownContent: function() {
    this._resetSubContainers();
    this._super();
  },

  _resetSubContainers: function() {
    var subContainers = get(this, 'subContainers');
    if (subContainers) {
      subContainers.forEach(function(subContainer, groupVal) {
        if (subContainer) { subContainer.destroy(); }
      });
    }
    this.set('subContainers', Ember.Map.create());
  },

  createGroupsArray: function() {
    var container = get(this, 'container');
    var groupsControllerInstance = container.lookup('controller:' +
      this.get('groupsController'));
    Ember.assert("Couldn't find groupsController %@".fmt(
        this.get('groupsController')), !!groupsControllerInstance);
    groupsControllerInstance.set('content', Ember.A());
    return groupsControllerInstance;
  },

  createGroupArray: function(groupVal) {
    var container = get(this, 'container');
    var subContainers = get(this, 'subContainers'),
      groupControllerInstance = subContainers.get(groupVal);

    if(!groupControllerInstance) {
      var groupControllerFactory = container.lookupFactory('controller:' + get(this, 'groupController'));
      if (!groupControllerFactory) {
        throw new Error('Could not resolve groupController: "' +
          get(this, 'groupController') + '"');
      }
      groupControllerInstance = groupControllerFactory.create({
        content: Ember.A(),
        group: groupVal
      });
      subContainers.set(groupVal, groupControllerInstance);
    }
    return groupControllerInstance;
  },

  removeGroupArray: function(groupVal) {
    var subContainers = get(this, 'subContainers'),
      subContainer = subContainers.get(groupVal);
    if(subContainer) {
      subContainers.delete(groupVal);
      subContainer.destroy();
    }
    this._super(groupVal);
  }
});
