Breadcrumb.Serializer = DS.ActiveModelSerializer.extend({
  keyForRelationship: function(key, kind) {
    key = Ember.String.decamelize(key);
    if (kind === "belongsTo") {
      return key + "_id";
    } else if (kind === "hasMany") {
      return key;  // DO NOT ADD _ids, *yet*
    } else {
      return key;
    }
  },
  serializeHasMany: function(record, json, relationship) {
    var key   = relationship.key,
        attrs = this.get('attrs'),
        primaryKey = this.get('primaryKey'),
        embed = attrs && attrs[key] && attrs[key].embedded === 'ids';
    if (embed) {
      var array = record._data[key] || [];
      json[this.keyForAttribute(key)] = array.mapBy(primaryKey);
    }
  }
});

Breadcrumb.Adapter = DS.ActiveModelAdapter.extend({
  host: Ember.computed(function() {
    var ssl = location.protocol==='http:'?'':'s';
    return 'http'+ssl+'://' + BREADCRUMB_ENV.SITE_DOMAIN;
  }),
  namespace: 'api/objects',
  bulkCommit: false,

  ajax: function(url, type, hash) {
    hash = hash || {};
    hash.cache = false;
    return this._super(url, type, hash);
  }
});

Breadcrumb.Store = DS.Store.extend({
  modelFor: function(key) {
    var model = this._super(key);
    model.typeKey = model.typeKey.decamelize();
    return model;
  },

  first: function(type, id) {
    if (Ember.typeOf(id) === 'object') {
      return this.firstQuery(type, id);
    } else {
      return this.find(type, id);
    }
  },

  firstQuery: function(type, query) {
    return this.findQuery(type, query).then(function(objects) {
      if(!objects || !objects.get('length')) { return null; }
      return objects.get('firstObject');
    });
  },

  findWithSideload: function(type, query, sideload) {
    if(typeof query === 'string') { query = {id: query}; }
    query = $.extend(query, {sideload: sideload});
    return new Ember.RSVP.Promise(B.bind(function(resolve, reject) {
      this.findQuery(type, query).then(function(array) {
        if(!array.get('length')) {
          reject(new Error("Object not found matching query " +
            JSON.stringify(query)));
          return;
        }
        resolve(array.objectAt(0));
      }, reject);
    }, this));
  }
});

// ************************************************************
// **        Models
// ************************************************************

Breadcrumb.Model = DS.Model.extend({
  _reloadLater: false,
  _reloadLaterResolve: null,
  _reloadAgain: null,

  safeReload: function(force) {
    var isReloading = this.get('isReloading'), self = this;
    if(!isReloading && !this.get('isLoading') && !this.get('inFlight')) {
      return this.reload(); }
    return new Ember.RSVP.Promise(function(resolve) {
      self._reloadLater = true;
      self._reloadLaterResolve = resolve;
      self._reloadAgain = !!force;
    });
  },

  // perform safe reload if it is called for.
  isReloadingDidChange: function() {
    if(this._reloadLater && !this.get('isReloading') &&
        !this.get('isLoading')) {
      var reloadAgain = this._reloadAgain;
      this._reloadLaterResolve(this);
      this._reloadLaterResolve = null;
      this._reloadLater = false;
      this._reloadAgain = null;
      if(reloadAgain) { this.safeReload(); }
    }
  }.observes('isReloading', 'isLoading'),

  getDynamicProperty: function(propName, params) {
    var adapter = this.store.adapterFor(this.constructor.typeKey);
    var docName = adapter.pathForType(this.constructor.typeKey);
    var docId = this.id;
    return Breadcrumb.api.getDynamicProperty(docName, docId, propName, params);
  },
  triggerAction: function(actionName, actionParams) {
    var adapter = this.store.adapterFor(this.constructor.typeKey);
    var docName = adapter.pathForType(this.constructor.typeKey);
    var docId = this.id;
    return Breadcrumb.api.triggerAction(docName, docId, actionName,
      actionParams);
  },

  getLocalRoot: function() {
    var adapter = this.store.adapterFor(this.constructor.typeKey);
    var docName = adapter.pathForType(this.constructor.typeKey);
    return 'breadcrumb_' + docName + '_' + this.id;
  },

  localKeyForData: function(key) {
    return this.getLocalRoot() + '_' + key;
  },

  getLocal: function(key) {
    return Breadcrumb.localStorage.getJson(this.localKeyForData(key));
  },

  setLocal: function(key, value) {
    Breadcrumb.localStorage.setJson(this.localKeyForData(key), value);
  },

  clearLocal: function(key) {
    Breadcrumb.localStorage.clear(this.localKeyForData(key));
  }
});

Breadcrumb.Model.reopenClass({
  triggerCollectionAction: function(actionName, actionParams, opts) {
    var adapter = this.store.adapterFor(this.typeKey);
    var docName = adapter.pathForType(this.typeKey);
    return Breadcrumb.api.triggerCollectionAction(docName, actionName,
      actionParams, opts);
  }
});
