Breadcrumb.Assets = {};
Breadcrumb.Assets.loadAsset = function(asset) {
  return new Ember.RSVP.Promise(function(resolve, reject) {
    var assetUrl = Breadcrumb.api.siteUrl('/static/assets/' + asset);
    $.ajax({
      url: assetUrl,
      type: 'get',
      dataType: 'json',
      context: this,
      success: resolve,
      error: function(jqxhr, textStatus, err) {
        console.error('error loading asset', assetUrl, 'textStatus:', textStatus, 'err:', err);
        reject();
      }
    });
  });
};
