Breadcrumb.Paginatable = Ember.Mixin.create({

  count: null,

  contentMetaDidChange: function() {
    var meta = this.get('content.content.meta');
    if(meta) { this.set('count', meta.count); }
  }.observes('content.content.meta'),

  numPages: function() {
    var count = parseInt(this.get('count'), 10);
    var pageSize = parseInt(this.get('pageSize'), 10);
    return Math.ceil(count / pageSize) || 1;
  }.property('count', 'pageSize'),

  hasPrevPage: function() {
    return parseInt(this.get('page'), 10) > 1;
  }.property('page'),

  hasNextPage: function() {
    return parseInt(this.get('page'), 10) < this.get('numPages');
  }.property('page', 'numPages'),

  actions: {
    prevPage: function() {
      var page = parseInt(this.get('page'), 10);
      if(page <= 1) { return; }
      this.set('page', (page - 1));
    },
    nextPage: function() {
      var next = parseInt(this.get('page'), 10) + 1;
      if(next > this.get('numPages')) { return; }
      this.set('page', next);
    },
    firstPage: function() {
      this.set('page', 1);
    },
    lastPage: function() {
      this.set('page', this.get('numPages'));
    }
  }
});
