function optionAlias(path) {
  return Ember.computed('content.${path}', {
    get: function() {
      return this.option(path);
    },
    set: function(key, val) {
      return this.option(path, val);
    }
  });
}

Breadcrumb.GameOptionsProxy = Ember.Object.extend({
  // Because nested obj properties do not dirty model when changed
  // we need to manually copy options obj and re-set it
  content: null,

  option: function(key, newVal) {
    var options = this.get('content') || {};
    var oldVal = Ember.get(options, key);
    if(typeof newVal === 'undefined') { // get
      return oldVal;
    } else { // set
      if(oldVal !== newVal) {
        // because video quality select view sets val on render
        // and will dirty model even if val is unchanged
        Ember.set(options, key, newVal);
        this.set('content', $.extend({}, options));
      }
      return newVal;
    }
  },

  isSocialEnabled: optionAlias('is_social_enabled'),

  pointsForSharing: Ember.computed('content.points_for_sharing', {
    get: function() {
      return parseInt(this.option('points_for_sharing'), 10) || 0;
    },
    set: function(key, val) {
      return parseInt(this.option('points_for_sharing', val), 10) || 0;
    }
  }),
  leaderboardEnabled: optionAlias('leaderboard_enabled'),

  selectGalleryMediaEnabled: optionAlias('select_gallery_media_enabled'),

  galleryEnabled: optionAlias('gallery_enabled'),
  galleryShowAllTeams: optionAlias('gallery_show_all_teams'),
  vasTemplate: optionAlias('vas_template'),

  videoQuality: Ember.computed('content.video_quality', {
    get: function() {
      return this.option('video_quality') || this.get('videoQualityDefault');
    },
    set: function(key, val) {
      return this.option('video_quality', val) || this.get('videoQualityDefault');
    }
  }),
  videoQualityField: function() {
    return Breadcrumb.Behavior.get('movie').fields.findBy(
      'name', 'video_quality');
  }.property(),
  videoQualityOptions: Ember.computed.oneWay('videoQualityField.options'),
  videoQualityDefault: Ember.computed.oneWay('videoQualityField.default'),
  videoQualityLabel: function() {
    var qualityValue = this.get('videoQuality');
    var qualityOptions = this.get('videoQualityOptions');
    return Ember.get(qualityOptions.findBy('value', qualityValue), 'label');
  }.property('videoQuality'),

  videoMaxLength: Ember.computed('content.video_max_length', {
    get: function() {
      return this.option('video_max_length') || this.get('videoMaxLengthDefault');
    },
    set: function(key, val) {
      return this.option('video_max_length', val) || this.get('videoMaxLengthDefault');
    }
  }),

  videoMaxLengthField: function() {
    return Breadcrumb.Behavior.get('movie').fields.findBy(
      'name', 'video_max_length');
  }.property(),

  videoMaxLengthDefault: Ember.computed.oneWay('videoMaxLengthField.default'),

  videoPauseRecord: Ember.computed('content.video_pause_record', {
    get: function() {
      return this.option('video_pause_record') || this.get('videoPauseRecordDefault');
    },
    set: function(key, val) {
      return this.option('video_pause_record', val) || this.get('videoPauseRecordDefault');
    }
  }),
  videoPauseRecordField: function() {
    return Breadcrumb.Behavior.get('movie').fields.findBy(
      'name', 'video_pause_record');
  }.property(),
  videoPauseRecordDefault: Ember.computed.oneWay(
    'videoPauseRecordField.default'),

  videoDisableRotation: Ember.computed('content.video_disable_rotation', {
    get: function() {
      return this.option('video_disable_rotation') || this.get('videoDisableRotationDefault');
    },
    set: function(key, val) {
      return this.option('video_disable_rotation', val) || this.get('videoDisableRotationDefault');
    }
  }),
  videoDisableRotationField: function() {
    return Breadcrumb.Behavior.get('movie').fields.findBy(
      'name', 'video_disable_rotation');
  }.property(),
  videoDisableRotationDefault: Ember.computed.oneWay(
    'videoDisableRotationField.default'),
});
