
Breadcrumb.Views.MissionShowFieldValueView = Ember.View.extend({
  tagName: 'span',
  templateName: "mission-show-field"
});

Breadcrumb.Views.MissionShowFieldTextListView = Ember.CollectionView.extend({
  tagName: 'ul',
  itemViewClass: Ember.View.extend({
    templateName: "mission-show-field"
  })
});

Breadcrumb.Views.MissionShowFieldTextAndCheckListView = Ember.CollectionView.extend({
  tagName: 'ul',
  classNames: 'unstyled',
  itemViewClass: Ember.View.extend({
    classNameBindings: 'content.checked::muted',
    templateName: "mission-show-field-text-and-check-list-item"
  })
});
Breadcrumb.Views.MissionShowFieldTextAndRadioListView = Ember.CollectionView.extend({
  tagName: 'ul',
  classNames: 'unstyled',
  itemViewClass: Ember.View.extend({
    templateName: "mission-show-field-text-and-radio-list-item"
  })
});
Breadcrumb.Views.MissionShowFieldTextAndNumberListView = Ember.CollectionView.extend({
  tagName: 'ul',
  classNames: 'unstyled',
  itemViewClass: Ember.View.extend({
    templateName: "mission-show-field-text-and-number-list-item"
  })
});

Breadcrumb.Views.MissionShowFieldTextAndValueListView = Ember.CollectionView.extend({
  tagName: 'ul',
  classNames: 'unstyled',
  itemViewClass: Ember.View.extend({
    templateName: "mission-show-field-text-and-value-list-item"
  })
});

Breadcrumb.Views.StepCorrectView = Ember.View.extend({
  templateName: 'step-correct',
  classNames: 'step-answer',
  choices: '',
  answer: function() {
    var answer = '';
    this.get('step.fieldsAndValues').forEach(B.bind(function(obj) {
      var name = obj.field.name;
      if(name === 'correct' || name === 'plant_password' ||
          name === 'correct_number') {
        answer = obj.value;
      } else if (name === 'choices') {
        var choices = '';
        obj.value.forEach(function(choice) {
          if(choice.checked) { answer += choice.text + ', '; }
          else { choices += choice.text + ', '; }
        });
        this.set('choices', choices);
        answer = answer.slice(0, -2);
      }
    }, this));
    return answer;
  }.property(),
  answerIsNotEmpty: function() {
    return this.get('answer') || (this.get('answer')) === 0;
  }.property('answer')
});

Breadcrumb.Views.StepFieldView = Ember.View.extend({
  templateName: 'step-field',
  classNameBindings: [':step-field', 'content.isDefault:muted',
    'fieldClassName'],

  shouldShow: function() {
    // dont show default video settings because this is misleading
    // step.fieldsAndValues returns default value if undefined
    // but if video value is undefined, mission will use game video settings
    var videoFields = Breadcrumb.Behavior.fieldsets.video,
    isVideoField = videoFields.indexOf(this.get('content.field.name')) >= 0;
    return !(isVideoField && this.get('content.isDefault'));
  }.property('content.field.name', 'content.isDefault'),

  isArray: function() {
    return Ember.isArray(this.get('content.value'));
  }.property('content.value'),

  fieldClassName: function() {
    return 'step-field-' + this.get('content.field.name').replace(/_/g, '-');
  }.property('content.field.name'),

  valueViewClass: function() {
    var view = {
      'list:text': 'mission-show-field-text-list',
      'list:text+check': 'mission-show-field-text-and-check-list',
      'list:text+radio': 'mission-show-field-text-and-radio-list',
      'list:text+number': 'mission-show-field-text-and-number-list',
      'list:text+value': 'mission-show-field-text-and-value-list'
    }[this.get('content.field.type')];
    view = view || 'mission-show-field-value';
    return view;
  }.property('content.value'),

  valueView: function() {
    var value = this.get('content.value');
    if (value && typeof value === 'object') {
      var object_key = this.get('content.field.object_key');
      if (object_key && object_key in value) {
        return value[object_key];
      }
    }
    return value;
  }.property('content.value')
});

Breadcrumb.Views.StepView = Ember.View.extend({
  templateName: 'step',
  classNames: 'mission-step',
  correctOnly: false,
  step: Ember.computed.oneWay('content'),

  fieldsAndValues: function() {
    var correctFieldNames = ['correct', 'choices', 'plant_password',
      'correct_number'];
    var fieldsAndValues = this.get('step.fieldsAndValues') || [];
    return fieldsAndValues.filter(function(obj) {
      return correctFieldNames.indexOf(obj.field.name) === -1;
    });
  }.property('step.fieldsAndValues')
});
