Breadcrumb.WindowHeightMapView = EmberLeaflet.MapView.extend(
    B.Views.AdaptsToWindowHeight, {
  padding: 0,
  resizeSelectors: [],
  heightDefaults: {'this': '350px'},

  resize: function() {
    this._super();
    this._layer.invalidateSize();
  }
});
