Breadcrumb.Api = Ember.Object.extend({

  init: function() {
    this._super();
    this._clientId = Breadcrumb.Utils.guid();
  },

  siteUrl: function(url) {
    var ssl = location.protocol==='http:'?'':'s';
    return 'http'+ssl+'://' + BREADCRUMB_ENV.SITE_DOMAIN + url;
  },

  ajax: function(url, method, data, options) {
    var siteUrl = this.siteUrl(url);
    return new Ember.RSVP.Promise(function(resolve, reject) {
      var hash = $.extend({
        url: siteUrl,
        type: method,
        cache: false,
        dataType: 'json',
        data: data,
        timeout: 100000,
        success: function(data) { resolve(data); },
        error: function(xhrErr, status, errorThrown) {
          Ember.Logger.error(Ember.String.fmt("API error calling %@%@: %@",
            [url, Ember.isEmpty(data) ? "" :
            (" with data " + JSON.stringify(data)), errorThrown]));
          var err = new Error(errorThrown);
          if (xhrErr.responseText && xhrErr.responseText[0] === '{') {
            try {
              err.data = JSON.parse(xhrErr.responseText);
            } catch(e) {}
          }
          reject(err);
        }
      }, options);
      $.ajax(hash);
    });
  },
  performIdentityAction: function(actionUrl, method, data, opts) {
    return this.ajax("/play/" + actionUrl, method, data, opts);
  },
  getDynamicProperty: function(docName, docId, propName, params, opts) {
    var url = '/api/objects/' + docName + '/' + docId + '/' +
      propName.decamelize();
    return this.ajax(url, 'get', params, opts);
  },
  triggerAction: function(docName, docId, actionName, actionParams, opts) {
    var url = '/api/events/' + docName + '/' + docId + '/trigger_action';
    var paramString = actionParams && JSON.stringify(actionParams);
    var data = {
      name: actionName.decamelize(),
      params: paramString,
      source_id: this._clientId};
    return this.ajax(url, 'post', data, opts);
  },
  triggerCollectionAction: function(docName, actionName, actionParams, opts) {
    var url = '/api/events/' + docName + '/trigger_action';
    var paramString = actionParams && JSON.stringify(actionParams);
    var data = {name: actionName.decamelize(), params: paramString};
    return this.ajax(url, 'post', data, opts);
  }
});
