Breadcrumb.Location = {
  watch: function(success, error) {
    return Breadcrumb.NativeHooks.watchLocation(success, error);
  },
  unwatch: function(watchId) {
    Breadcrumb.NativeHooks.unwatchLocation(watchId);
  },
  getCurrent: function(success, error) {
    Breadcrumb.NativeHooks.getCurrentLocation(success, error);
  }
};
