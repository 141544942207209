
Breadcrumb.ColorPickerComponent = Ember.TextField.extend({
  classNames: ['color-picker-component'],

  settings: {
    animationSpeed: 0,
    changeDelay: 250,
    hideSpeed: 0,
    showSpeed: 0
  },

  didInsertElement: function() { this.$().minicolors(this.get('settings')); },
  willDestroyElement: function() { this.$().minicolors('destroy'); },

  valueDidChange: function() {
    var val = this.get('value');
    if (val && val.substr(0,1) !== '#') {
      val = '#' + val;
      this.set('value', val);
    }
    if(val.length > 6) {
      // update color-picker when value set externally from keyFinder
      this.$().minicolors('value', val);
    }
  }.observes('value')
});

// template usage: {{color-picker value=propertyOnController}}
